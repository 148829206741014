<template>
  <div class="popular-learning">
    <div class="popular-learning-title">{{ title }}</div>
    <div class="popular-learning-subtitle">{{ subTitle }}</div>
    <div class="swiper-button-prev">
      <icon
        name="swiper-button-prev-btn"
        class="swiper-button-prev-btn-icon"
      ></icon>
    </div>
    <div class="swiper-button-next">
      <icon
        name="swiper-button-next-btn"
        class="swiper-button-next-btn-icon"
      ></icon>
    </div>
    <div class="swiper-index">
      <div class="swiper-index-page-text">Page&nbsp;</div>
      {{ viewState.realIndex }}/{{ viewState.coursesCount }}
    </div>
    <swiper
      class="swiper-container"
      :loop="viewState.coursesCount > 1"
      :slidesPerView="3"
      :slidesPerGroup="3"
      :breakpoints="breakpoints"
      :navigation="{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }"
      @activeIndexChange="activeIndexChange"
    >
      <swiper-slide
        v-for="(x, index) in viewState.coursesCount"
        :key="index"
        :class="'popular-learning-items'"
      >
        <popularLearningItem
          :imgIndex="(index % 5) + 1"
          :course="courses[index]"
          :levels="levels"
          :isEnterprise="isEnterprise"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import popularLearningItem from "./PopularLearningItem.vue";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import { defineComponent, reactive } from "vue";
SwiperCore.use([Navigation]);

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    courses: {
      type: Array,
      required: true,
      default: () => []
    },
    levels: {
      type: Array,
      required: false,
      default: () => []
    },
    isEnterprise: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  components: {
    popularLearningItem,
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const viewState = reactive({
      coursesCount: props.courses.length ? props.courses.length : 1,
      realIndex: 1,
    });

    const breakpoints = {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },

      1000: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 28,
      },
      1366: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 28,
      },
    };

    // method
    const activeIndexChange = ({ realIndex }) => {
      viewState.realIndex = realIndex + 1;
    };

    return {
      viewState,
      breakpoints,

      // method
      activeIndexChange,
    };
  },
});
</script>
<style lang="scss" scoped>
.popular-learning {
  max-width: 1316px;
  width: 100%;
  margin-top: 100px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include mq(sm) {
    margin-top: 60px;
    margin-right: 20px;
    width: calc(100% - 20px);
    height: 851px;
  }
  .swiper-button-prev {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 125px;
    top: 135px;
    .swiper-button-prev-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      position: relative;
      top: 30px;
      left: 50px;
      right: unset;
      .swiper-button-prev-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .swiper-button-next {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 65px;
    top: 135px;
    .swiper-button-next-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      position: relative;
      top: 0;
      left: 90px;
      right: unset;
      .swiper-button-next-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .swiper-index {
    display: none;
    @include mq(sm) {
      display: flex;
      height: 15px;
      align-items: baseline;
      position: relative;
      top: -24px;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black-50;
      justify-content: flex-end;
      padding-right: 30px;
    }
  }
  .popular-learning-title {
    margin-left: 115px;
    margin-top: 65px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: $black;
    text-align: left;
    position: relative;
    margin-right: 50px;
    &::before {
      position: absolute;
      content: "";
      width: 40px;
      height: 0px;
      left: 0;
      bottom: -22px;
      border-bottom: 4px solid $black;
    }
    @include mq(sm) {
      margin-left: 50px;
      margin-top: 30px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0.08em;
      color: $black;
      height: 80px;
      &::before {
        left: 0;
        bottom: -12px;
      }
    }
  }
  .popular-learning-subtitle {
    margin-left: 115px;
    margin-top: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    color: $black;
    opacity: 0.5;
    @include mq(sm) {
      margin-left: 50px;
      margin-top: 32px;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black;
    }
  }
  .swiper-container {
    margin-top: 60px;
    margin-left: 115px;
    width: calc(100% - 180px);
    filter: drop-shadow(0px 0px 45px rgba(0, 0, 0, 0.1));
    .popular-learning-items {
      width: 360px;
      height: 715px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    @include mq(sm) {
      margin-top: 15px;
      margin-left: 50px;
      margin-right: 30px;
      width: 275px;
      .popular-learning-items {
        height: 573px;
      }
    }
  }
}
</style>
