import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import icon from "@/components/SvgIcon";
import "destyle.css/destyle.min.css";
import "element-plus/dist/index.css";
import "@/assets/styles/index.scss";
import { createGtm } from 'vue-gtm';
import "jquery";
const app = createApp(App);

import globalStore, { GlobalStoreKey } from "@/store/global";
app.provide(GlobalStoreKey, globalStore());

app.component("icon", icon);
const req = require.context("./icons/svg", false, /\.svg$/);
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
requireAll(req);

app
  .use(store)
  .use(router)
  .use(
    createGtm({
      id: "GTM-KKQCT9K",
      vueRouter: router,
    })
  )
  .mount("#app");

// パンくずのための処理
router.beforeEach((from, to) => {
  if (from.name === "courseDetail") {
    store.dispatch("changeCourseDetailURL", from.params.courseCode);
  }
  if (to.name === "courseDetail" && from.name === "lectureDetail") {
    // 前ページがコース詳細で、現在が講座詳細の時
    store.dispatch("changeCourseDetailFlag", true);
    store.dispatch("changeLectureDetailURL", from.params.lectureNo);
  } else if (to.name === "lectureDetail" && from.name === "view") {
    // 前ページが講座詳細で現在が視聴画面の時
    store.dispatch("changeCourseDetailFlag", true);
  } else {
    // 基本的にページ表示時にstoreの値をfalseにするが講座詳細はfalse にしない
    if (from.name !== "lectureDetail") {
      store.dispatch("changeCourseDetailFlag", false);
    }
  }
});
