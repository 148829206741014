<template>
  <svg :class="svgClass" aria-hidden="true" @mouseenter="onMouseenter" @mouseleave="onMouseOut">
    <use :xlink:href="iconName"></use>
  </svg>
</template>
<script>
export default {
  name: 'icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    hoverName: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    class: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHover: false
    }
  },
  computed: {
    iconName() {
      if (this.hoverName !=='' && !this.disable) {
        if (this.isHover) {
          return `#icon-${this.hoverName}`
        } else {
          return `#icon-${this.name}`
        }
      } else {
        return `#icon-${this.name}`
      }
    },
    svgClass() {
      if (this.class) {
        return 'icon ' + this.class
      } else {
        return 'icon'
      }
    },
  },

  methods: {
    onMouseenter() {
      this.isHover = true
    },
    onMouseOut () {
      this.isHover = false
    }
  }
}
</script>