import axios from 'axios'
import store from '@/store'

const http = axios.create({
    // 共通定義
    baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
})

http.interceptors.request.use(
    config => {
        const token = store.getters.token
        if (token) {
            config.headers.common['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

http.interceptors.response.use(response => {
    // APIのリクエストに成功した場合はそのまま結果を返す
    return response
}, error => {
    // トークン切れの場合、専用のエラー画面に遷移する

    // if (error.config && error.response && error.response.status === 500) {
    //     Vue.$router.push({
    //         path: "Top",
    //     })
    // }

    // 「503」が返ってきたときはメンテナンス画面に遷移する
    if (error.response.status === 503) {
        store.dispatch("changeIsMaintenance", true);
    }

    // ログインAPI・仮会員登録関連API以外で認証エラーが出た場合は強制的にログアウトする
    if (error.response.status === 401
        && error.response.config.url !== "/api/login"
        && error.response.config.url.indexOf("/api/temporary-users/") !== 0) {
        console.log("expired token")
        store.dispatch("logout");
    }

    // エラー終了時にPromiseを返す
    return Promise.reject(error)
})
export default http
