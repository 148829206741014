<template>
  <template v-if="!store._state.data.isMaintenance">
    <commonHeader></commonHeader>
    <commonNavBar
      v-if="
        menuPath === 'course' ||
        menuPath === 'course-detail' ||
        menuPath === 'lecture' ||
        menuPath === 'lecture-detail' ||
        menuPath === 'view' ||
        menuPath === 'search' ||
        menuPath === 'teacher' ||
        menuPath === 'teacher-detail'
      "
    ></commonNavBar>
    <router-view />
    <commonFooter></commonFooter>
  </template>
  <Maintenance v-else />
</template>

<script>
import commonHeader from "@/components/CommonHeader/Header.vue";
import commonNavBar from "@/components/CommonNavBar/NavBar.vue";
import commonFooter from "@/components/CommonFooter/footer.vue";
import Maintenance from "@/views/Maintenance.vue";

import { useRoute } from "vue-router";
import store from "../store";
import {
  defineComponent,
  onMounted,
  ref,
  watch
} from "vue";
export default defineComponent({
  name: "home",
  components: {
    commonHeader,
    commonNavBar,
    commonFooter,
    Maintenance
  },
  setup() {
    const route = useRoute();
    const menuPath = ref("");
    
    watch(
      () => route.path,
      () => {
        menuPath.value = route.path.split('/')[1];
      }
    );
    onMounted(async () => {
      menuPath.value = route.path.split('/')[1];
    });

    return {
      store,
      menuPath,
    }
  },
})
</script>
