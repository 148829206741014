<template>
  <div class="bottom-btn">
    <div class="btn" @click="clickButton">
      <div class="btn-label">{{ label }}</div>
      <div class="btn-icon">
        <icon name="bottom-btn-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: ['label', 'routeName'],
  setup(props) {
    const router = useRouter();

    const clickButton = () => {
      router.push({
        name: props['routeName'],
      });
    };

    return {
      clickButton,
    };
  },
});
</script>

<style lang="scss" scoped>
.bottom-btn {
  max-width: 1366px;
  width: calc(100% - 230px);
  min-height: 145px;
  border-bottom: 0.5px solid $black-50;
  @include mq(sm) {
    width: calc(100% - 100px);
    min-height: 120px;
  }
  .btn {
    cursor: pointer;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    backdrop-filter: blur(10px);
    @include mq(sm) {
      height: 60px;
    }
    .btn-label {
      margin-left: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $primary;
      @include mq(sm) {
        margin-left: 20px;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $primary;
      }
    }
    svg {
      width: 40px;
      height: 10px;
      margin-right: 20px;
    }
  }
}
</style>