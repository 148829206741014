<template>
  <div>
    <div class="panel-body">
      <slot />
    </div>
    <div v-if="!iconNone" class="layout-bg-bttom--light-blue"></div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";

export default {
  props: ["iconNone"],
  setup() {
    const state = reactive({});

    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel-body {
  margin-bottom: 50px;
  padding: 65px;
  background-color: $white;
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  backdrop-filter: blur(10px);
  @include mq(sm) {
    padding: 60px 30px;
  }
}
.layout-bg-bttom--light-blue {
  width: 584px;
  height: 584px;
  background: $grey;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  bottom: -310px;
  right: -310px;
  overflow: hidden;
  @include mq(sm) {
    width: 255px;
    height: 255px;
    bottom: -155px;
    right: -155px;
  }
}
</style>