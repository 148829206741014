<template>
  <div class="commonHeader">
    <div id="pcHeader" class="pc-header">
      <el-dialog
        v-model="viewState.searchVisible"
        lock-scroll
        width="100%"
        top="0"
        :show-close="false"
        :destroy-on-close="true"
        custom-class="pc-header-search-dialog"
      >
        <div class="pc-header-search-bar">
          <div class="pc-header-input">
            <input
              type="text"
              v-model="viewState.searchText"
              @keypress.enter="searchAction"
              ref="searchContentRef"
            />
          </div>
          <div class="pc-search-header-btn">
            <div
              class="pc-search-btn"
              @click="searchAction"
              :class="viewState.searchVisible ? 'pc-search-btn-animation' : ''"
            >
              <icon name="pc-search-icon" class="pc-search-icon"></icon>
            </div>
            <div
              class="pc-close-search-btn"
              v-if="viewState.searchVisible"
              @click="closePcSearchClick"
            >
              <icon
                name="pc-close-search-icon"
                class="pc-close-search-icon"
              ></icon>
            </div>
          </div>
        </div>
      </el-dialog>

      <div class="pc-menu"  v-if="!viewState.searchVisible">
        <div class="pc-logo">
          <template v-if="viewState.isDisplayed">
            <img
              v-if="isEnterprise && viewState.headerLogo !== null"
              :src="viewState.headerLogo?.image_url"
              @click="$router.push('/')"
            />
            <icon
              v-else
              name="logo"
              @click="$router.push('/')"
            ></icon>
          </template>
        </div>
        <div class="menu-list">
          <template
            v-if="isEnterprise"
          >
            <div
              v-for="(item, index) in viewState.enterpriseMenuList"
              :key="`enterprise-` + index"
              class="menu-item"
              :class="activeClass(item.path)"
              @click="menuClick(item)"
            >
              {{ item.label }}
              <div class="isActive-spot"></div>
            </div>
          </template>
          <template
            v-else
          >
            <div
              v-for="(item, index) in viewState.menuList"
              :key="index"
              class="menu-item"
              :class="activeClass(item.path)"
              @click="menuClick(item)"
            >
              {{ item.label }}
              <div class="isActive-spot"></div>
            </div>
          </template>
        </div>
        <div class="header-control">
          <div class="header-search">
            <icon
              name="search-btn"
              class="search-btn"
              @click="pcSearchClick"
            ></icon>
          </div>
          <el-popover
            v-if="userStore.isLogin"
            placement="bottom-start"
            trigger="click"
            width="214px"
            :offset="16"
            :popper-class="`pc-header-login-popper ${
              userStore.servicePlanId == 1
                ? ''
                : 'pc-header-login-popper-corporation'
            }`"
            :hide-after="0"
            :transition="'none'"
          >
            <div
              class="pc-header-role"
              :class="
                userStore.servicePlanId == 1 ? '' : 'pc-header-role-corporation'
              "
            >
              <div class="pc-header-role-text">
                {{ userStore.servicePlanId == 1 ? "個人" : "法人" }}
              </div>
            </div>
            <router-link to="/mypage" class="my-page-btn">
              <div class="my-page-btn-text">マイページ</div>
            </router-link>
            <router-link to="/profile/update" class="account-information">
              <div class="account-information-text">アカウント情報</div>
            </router-link>
            <div @click="noticeClick" class="notice">
              <div class="notice-text">通知</div>
              <div class="notice-new" v-if="userStore.hasUnread">New</div>
            </div>
            <div class="logout-btn" @click="logoutClick">
              <div class="logout-btn-text">ログアウト</div>
              <icon name="logout-btn" />
            </div>

            <template #reference>
              <div class="pc-header-login">
                <div class="user-icon">
                  <icon
                    name="pc-header-user-icon"
                    class="pc-header-user-icon"
                  />
                  <div class="unread" v-if="userStore.hasUnread"></div>
                </div>
                <div class="my-page">マイページ</div>
                <div class="header-drop-icon">
                  <icon name="header-drop-icon" class="header-drop-icon" />
                </div>
              </div>
            </template>
          </el-popover>
          <div class="pc-header-btn" v-else>
            <div class="login-btn" @click="loginClick">ログイン</div>
            <div class="reg-btn" @click="regClick">新規登録</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="sp-header"
      :class="!viewState.searchVisible && 'sp-header-center'"
    >
      <el-dialog
        v-model="viewState.searchVisible"
        lock-scroll
        width="100%"
        top="0"
        :show-close="false"
        :destroy-on-close="true"
        custom-class="sp-header-search-dialog"
      >
        <div class="sp-header-input">
          <input
            type="text"
            v-model="viewState.searchText"
            @keypress.enter="searchAction"
            ref="spSearchContentRef"
          />
        </div>
        <div class="sp-header-btn">
          <div
            class="sp-search-btn"
            @click="searchAction"
            :class="viewState.searchVisible ? 'sp-search-btn-animation' : ''"
          >
            <icon name="sp-search-icon" class="sp-search-icon"></icon>
          </div>
          <div
            class="sp-close-search-btn"
            v-if="viewState.searchVisible"
            @click="viewState.searchVisible = false"
          >
            <icon
              name="sp-close-search-icon"
              class="sp-close-search-icon"
            ></icon>
          </div>
        </div>
      </el-dialog>

      <div class="logo" v-if="!viewState.searchVisible">
        <template v-if="viewState.isDisplayed">
          <img
            v-if="isEnterprise && viewState.headerLogo !== null"
            :src="viewState.headerLogo?.image_url"
            @click="$router.push('/')"
          />
          <icon
            v-else
            @click="$router.push('/')"
            name="logo"
          ></icon>
        </template>
      </div>
      <div class="sp-header-btn" v-if="!viewState.searchVisible">
        <div
          class="sp-search-btn"
          @click="spSearchClick"
          :class="viewState.searchVisible ? 'sp-search-btn-animation' : ''"
        >
          <icon name="sp-search-icon" class="sp-search-icon"></icon>
        </div>
        <div
          class="sp-close-search-btn"
          v-if="viewState.searchVisible"
          @click="viewState.searchVisible = false"
        >
          <icon name="sp-close-search-icon" class="sp-close-search-icon"></icon>
        </div>
        <div
          class="sp-menu-btn"
          :class="
            userStore.isLogin && userStore.hasUnread
              ? 'sp-menu-btn-message'
              : ''
          "
          v-else
          @click="menuBtnClick"
        >
          <icon name="sp-menu-icon" class="sp-menu-icon"></icon>
        </div>
      </div>
    </div>
    <sp-menu
      :menuVisible="viewState.menuVisible"
      @closeMenu="closeMenu"
      @loginClick="loginClick"
      @logoutClick="logoutClick"
    />
    <loginDialog
      :isShowLogin="viewState.showLogin"
      @closeDialog="viewState.showLogin = false"
    />
  </div>
</template>

<script>
import http from "@/utils/http";
import spMenu from "./Menu.vue";
import { ElDialog, ElPopover } from "element-plus";
import { useStore } from "vuex";
import loginDialog from "@/components/CommonHeader/loginDialog.vue";
import { useRouter, useRoute } from "vue-router";
import { useGlobalStore } from "@/store/global";
import {
  defineComponent,
  reactive,
  nextTick,
  ref,
  computed,
  onMounted,
  watch
} from "vue";
export default defineComponent({
  components: {
    spMenu,
    ElDialog,
    ElPopover,
    loginDialog,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const userStore = store.state.user;
    const isLogin = computed(() => store.state.user.isLogin);
    const isEnterprise = computed(() => store.state.user.isEnterprise)
    const { searchStore } = useGlobalStore();

    const searchContentRef = ref();
    const spSearchContentRef = ref();

    const viewState = reactive({
      menuActive: "1",
      menuList: [
        { label: "学習コース", path: "/course" },
        { label: "講座一覧", path: "/lecture" },
        { label: "講師一覧", path: "/teacher" },
        { label: "ジッセン!とは", path: "/about" },
        { label: "FAQ", path: "/faq" },
        { label: "お問い合わせ", path: "/inquiry/input" },
      ],
      enterpriseMenuList: [
        { label: "学習コース", path: "/course" },
        { label: "講座一覧", path: "/lecture" },
        { label: "お問い合わせ", path: "/inquiry/input" },
      ],
      isDisplayed: false,
      searchVisible: false,
      menuVisible: false,
      showLogin: false,
      searchText: "",
      headerLogo: null
    });

    const login = async () => {
      await store.dispatch("login");
    };
    const customersMe = async () => {
      await store.dispatch("customersMe");
    };
    const noticesCheckUnread = async () => {
      await store.dispatch("noticesCheckUnread");
    };
    const logout = () => {
      store.dispatch("logout");
    };
    const changeRole = (val) => {
      store.dispatch("changeRole", val);
    };
    const setLogin = (email, password) => {
      store.dispatch("setLogin", { email, password });
    };
    const clearLogin = () => {
      store.dispatch("calcHeaderToMypage");
    };
    const noticeClick = () => {
      if (route.name === "mypage") {
        store.dispatch("changeNoticeTab", 1);
      } else {
        router.push("/mypage");
        store.state.user.headerToNoticeTab = true;
      }
    };
    const activeClass = (current) => {
      if (store._state.data.courseDetail !== true) {
        if (current !== "/") {
          if (route.path.indexOf(current.split("/")[1]) > -1) {
            return "isActive";
          }
        } else if (route.path === current) {
          return "isActive";
        }
      } else {
        if (
          route.name === "lectureDetail" &&
          current.split("/")[1] === "course"
        ) {
          return "isActive";
        } else if (
          route.name === "view" &&
          current.split("/")[1] === "course"
        ) {
          return "isActive";
        }
      }
    };
    const pcSearchClick = () => {
      viewState.searchVisible = !viewState.searchVisible;
      const element = document.getElementById("pcHeader");
      element.style.backdropFilter = "none";
      nextTick(() => {
        viewState.searchVisible && searchContentRef.value.focus();
      });
    };
    const spSearchClick = () => {
      viewState.searchVisible = !viewState.searchVisible;
      nextTick(() => {
        viewState.searchVisible && spSearchContentRef.value.focus();
      });
    };
    const closePcSearchClick = () => {
      const element = document.getElementById("pcHeader");
      element.style.backdropFilter = "";
      viewState.searchVisible = false;
    };
    const menuBtnClick = () => {
      viewState.menuVisible = true;
    };
    const closeMenu = () => {
      viewState.menuVisible = false;
    };
    const loginClick = () => {
      viewState.showLogin = true;
    };
    const regClick = () => {
      router.push("/apply");
    };
    const logoutClick = () => {
      logout();
    };
    const menuClick = (item) => {
      router.push(item.path);
    };
    const searchAction = () => {
      searchStore.setText(viewState.searchText);
      router.push("/search");
      nextTick(() => {
        closePcSearchClick();
      });
    };
    const fetchHeaderLogo = async () => {
      return await http
        .get("/api/header-logos")
        .then((response) => {
          console.dir(response.data);
          if (response.data.length > 0) {
            viewState.headerLogo = response.data[0];
          }

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const reInit = async () => {
      console.log("re-init");

      if (isEnterprise.value) {
        await fetchHeaderLogo();
      }

      viewState.isDisplayed = true; // 表示OK
    };
    
    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで初期処理を行う
        console.log("skip on-mount process");
        return;
      }

      if (isEnterprise.value) {
        await fetchHeaderLogo();
      }

      viewState.isDisplayed = true; // 表示OK
    });
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );

    return {
      viewState,
      searchContentRef,
      spSearchContentRef,
      userStore,
      isEnterprise,
      login,
      customersMe,
      noticesCheckUnread,
      logout,
      changeRole,
      setLogin,
      clearLogin,
      noticeClick,
      activeClass,
      pcSearchClick,
      spSearchClick,
      closePcSearchClick,
      menuBtnClick,
      closeMenu,
      loginClick,
      regClick,
      logoutClick,
      menuClick,
      searchAction,
    };
  },
});
</script>

<style lang="scss" scoped>
.ctrl {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 9999;
}
.pc-header {
  max-width: 1366px;
  min-height: 80px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(50px);
  z-index: 3;
  padding: 0 50px;
  margin: 0 auto;
  @include mq(sm) {
    display: none;
  }

  @keyframes sp-header-input-keyframe {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  .pc-header-search-bar {
    margin-left: auto;
    margin-right: auto;
    max-width: 1366px;
    padding: 0 50px;
    display: flex;
    width: 100%;
    align-items: center;

    .pc-header-input {
      height: 40px;
      margin: 0 -18px 0 20px;
      position: relative;
      flex: 1;
      input {
        height: 40px;
        min-height: 40px;
        border: none;
        padding: 0;
        font-size: 20px;
        border-radius: 0px;
        &:focus {
          outline: none;
          padding: 0;
          padding-right: 25px;
          border-radius: 0px;
        }
      }
      &::after {
        content: "";
        position: absolute;
        border-bottom: 1px solid $black;
        height: 0;
        width: 0;
        right: 0;
        bottom: 0;
        animation: sp-header-input-keyframe 0.25s ease-in-out 0s 1 forwards;
      }
    }
    .pc-search-header-btn {
      display: flex;
      .pc-search-btn {
        width: 60px;
        height: 60px;
        background: $black;
        z-index: 1;
      }
      @keyframes pc-search-btn-keyframe {
        from {
          width: 60px;
          height: 60px;
          border-radius: 0px;
          margin-top: 0px;
        }
        to {
          margin-top: 10px;
          width: 40px;
          height: 40px;
          border-radius: 40px;
        }
      }
      .pc-search-btn-animation {
        animation: pc-search-btn-keyframe 0.15s ease-in-out 0s 1 forwards;
      }
      .pc-menu-btn,
      .pc-close-search-btn {
        width: 60px;
        height: 60px;
      }
      .pc-search-btn,
      .pc-menu-btn,
      .pc-close-search-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .pc-menu {
    min-height: 80px;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    &::after {
      content: "";
      position: absolute;
      height: 0;
      left: 0;
      bottom: 0;
      right: 96px;
      border-bottom: 0.5px solid $black-50;
    }
    @include mq(sm) {
      display: none;
    }
    .pc-logo {
      width: 120px;
      height: 80px;
      margin-right: 4%;
      svg, img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .menu-list {
      display: flex;
      padding-top: 2px;
      min-height: 80px;
      align-items: center;
      .menu-item {
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.08em;
        color: rgba(0, 0, 0, 0.3);
        margin-right: 20px;
        white-space: nowrap;
        .isActive-spot {
          display: none;
        }
      }
      .isActive {
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.08em;
        color: $black;
        .isActive-spot {
          margin-top: 3px;
          display: flex;
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background: $black;
        }
      }
    }
    .header-control {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
    }
    .header-search {
      height: 40px;
      width: 40px;
      position: relative;
      margin-right: 20px;
      .search-btn {
        position: absolute;
        width: 40px;
        height: 40px;
        right: 0px;
        top: 0px;
        cursor: pointer;
      }
    }
    .pc-header-login {
      cursor: pointer;
      width: 214px;
      height: 80px;
      background: $black;
      display: flex;
      justify-content: center;
      align-items: center;
      .user-icon {
        height: 40px;
        width: 40px;
        position: relative;
        margin-right: 20px;
        .unread {
          content: "";
          right: -2px;
          top: -2px;
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: $secondary;
          box-shadow: 0px 0px 30px rgba(249, 118, 77, 0.3);
        }
        .pc-header-user-icon {
          height: 40px;
          width: 40px;
          margin-right: 20px;
        }
      }
      .my-page {
        width: 75px;
        height: 40px;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.08em;
        color: $white;
        white-space: nowrap;
        margin-right: 30px;
      }
      .header-drop-icon {
        display: flex;
        width: 11px;
        height: 7px;
      }
    }
    .pc-header-btn {
      max-width: 215px;
      display: flex;
      .login-btn {
        width: 120px;
        height: 80px;
        background: $black;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.08em;
        color: $white;
        cursor: pointer;
      }
      .reg-btn {
        cursor: pointer;
        margin-left: 35px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.08em;
        color: $black;
        white-space: nowrap;
      }
      .login-btn,
      .reg-btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.sp-header {
  display: none;
  @include mq(sm) {
    display: flex;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    background: #fff;
    .logo {
      flex: 1;
      height: 60px;
      border-bottom: 0.5px solid $black-50;
      svg, img {
        width: 90px;
        height: 60px;
        margin-left: 20px;
      }
    }
    @keyframes sp-header-input-keyframe {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
    .sp-header-input {
      height: 40px;
      margin: 10px -18px 0 20px;
      position: relative;
      flex: 1;
      input {
        height: 40px;
        min-height: 40px;
        border: none;
        padding: 0;
        font-size: 20px;
        border-radius: 0px;
        &:focus {
          outline: none;
          padding: 0;
          padding-right: 25px;
          border-radius: 0px;
        }
      }
      &::after {
        content: "";
        position: absolute;
        border-bottom: 1px solid $black;
        height: 0;
        width: 0;
        right: 0;
        bottom: 0;
        animation: sp-header-input-keyframe 0.25s ease-in-out 0s 1 forwards;
      }
    }
    .sp-header-btn {
      display: flex;
      justify-content: flex-end;
      .sp-search-btn {
        width: 60px;
        height: 60px;
        background: $black;
        z-index: 1;
      }
      @keyframes sp-search-btn-keyframe {
        from {
          width: 60px;
          height: 60px;
          border-radius: 0px;
          margin-top: 0px;
        }
        to {
          margin-top: 10px;
          width: 40px;
          height: 40px;
          border-radius: 40px;
        }
      }
      .sp-search-btn-animation {
        animation: sp-search-btn-keyframe 0.15s ease-in-out 0s 1 forwards;
      }
      .sp-menu-btn,
      .sp-close-search-btn {
        width: 60px;
        height: 60px;
      }
      .sp-search-btn,
      .sp-menu-btn,
      .sp-close-search-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .sp-menu-btn-message {
        &::after {
          content: "";
          right: 12px;
          top: 12px;
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: $secondary;
          box-shadow: 0px 0px 30px rgba(249, 118, 77, 0.3);
        }
      }
    }
    .sp-mask {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 60px;
      background: rgba(0, 0, 0, 0.4);
      z-index: 9999;
    }
  }
}
.sp-header-center {
  left: 50%;
  transform: translateX(-50%);
}
.commonHeader {
  background: $white;
}
@include mq(sm) {
  .commonHeader {
    height: 60px;
  }
}
::v-deep(.pc-header-search-dialog) {
  border-radius: 0px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    background: $white;
    padding: 0;
    display: flex;
    height: 80px;
    position: relative;
    justify-content: center;
  }
}
::v-deep(.sp-header-search-dialog) {
  border-radius: 0px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    background: $white;
    padding: 0;
    display: flex;
    height: 60px;
    position: relative;
  }
}
.pc-header-role {
  cursor: pointer;
  width: 214px;
  height: 30px;
  background: $secondary;
  display: flex;
  align-items: center;
  .pc-header-role-text {
    margin-left: 30px;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.09em;
    color: $grey;
  }
}
.pc-header-role-corporation {
  background: $primary;
}
.my-page-btn {
  cursor: pointer;
  width: 214px;
  height: 60px;
  background: $white;
  display: flex;
  align-items: center;
  text-decoration: none;
  .my-page-btn-text {
    margin-left: 30px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.09em;
    color: $black;
  }
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(54, 131, 245, 0.02),
        rgba(54, 131, 245, 0.02)
      ),
      $white;
    .my-page-btn-text {
      color: $primary;
    }
  }
}
.account-information {
  cursor: pointer;
  width: 214px;
  height: 60px;
  display: flex;
  align-items: center;
  text-decoration: none;
  .account-information-text {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    height: 100%;
    width: 100%;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.09em;
    color: $black;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
  }
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(54, 131, 245, 0.02),
        rgba(54, 131, 245, 0.02)
      ),
      $white;
    .account-information-text {
      color: $primary;
    }
  }
}
.notice {
  cursor: pointer;
  width: 214px;
  height: 60px;
  background: $white;
  display: flex;
  align-items: center;
  .notice-text {
    margin-right: 15px;
    margin-left: 30px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.09em;
    color: $black;
  }
  .notice-new {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.09em;
    color: $secondary;
  }
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(54, 131, 245, 0.02),
        rgba(54, 131, 245, 0.02)
      ),
      $white;
    .notice-text {
      color: $primary;
    }
  }
}
.logout-btn {
  cursor: pointer;
  width: 214px;
  height: 60px;
  background: $black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logout-btn-text {
    margin-left: 30px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.09em;
    color: $white;
  }
  svg {
    height: 16px;
    width: 16px;
    margin-right: 20px;
  }
}
</style>
