<template>
  <router-view />
</template>

<script>
import { watch } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    window.addEventListener("resize", () => {
      store.dispatch("calcIsSp");
    });
    store.dispatch("calcIsSp");
    // tokenあれば情報とってくる
    if (store.state.user.isLogin) {
      store.dispatch("customersMe");
      store.dispatch("noticesCheckUnread");
    }

    // A8アフィリエイト
    const searchParams = location.search;
    if (searchParams) {
      const urlSearchParams = new URLSearchParams(searchParams);
      const a8 = urlSearchParams.get('a8');
      if (a8) {
        store.dispatch("calcA8Id", a8);
      }
    }

    watch(
      () => store.state.user.isLogin,
      (isLogin) => {
        if (isLogin) {
          store.dispatch("customersMe");
          store.dispatch("noticesCheckUnread");
        }
      }
    );

    return { store };
  },
};
</script>
<style lang="scss">
#app {
  overflow: hidden;
}
</style>
