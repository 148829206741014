// 名前のつけようが無いlodashやunderscore等のような拡張機能を配置する場所
// @see https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore

import cloneDeep from "lodash/cloneDeep";

const util = {
  keyBy: (array, key) => {
    return (array || []).reduce(
      (r, x) => ({ ...r, [key ? x[key] : x]: x }),
      {}
    );
  },
  sortBy: (key) => {
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
  },
  stripTags: (str) => {
    return str.replace(/(<([^>]+)>)/gi, "");
  },
  cloneDeep: cloneDeep,
};
export default util;
