<template>
  <div class="common-swiper-video">
    <commonTitle :title="title" :subTitle="subTitle"></commonTitle>

    <div
      class="common-swiper-video-button-prev"
      :class="'common-swiper-video-button-prev' + cid"
    >
      <icon
        name="swiper-button-prev-btn"
        class="swiper-button-prev-btn-icon"
      ></icon>
    </div>
    <div
      class="common-swiper-video-button-next"
      :class="'common-swiper-video-button-next' + cid"
    >
      <icon
        name="swiper-button-next-btn"
        class="swiper-button-next-btn-icon"
      ></icon>
    </div>
    <div class="swiper-index">
      <div class="swiper-index-page-text">Page&nbsp;</div>
      {{ viewState.realIndex }}/{{ viewState.videosCount }}
    </div>
    <swiper
      class="swiper-container"
      :loop="viewState.videosCount > 1"
      :breakpoints="breakpoints"
      :navigation="{
        prevEl: '.common-swiper-video-button-prev' + cid,
        nextEl: '.common-swiper-video-button-next' + cid,
      }"
      @activeIndexChange="activeIndexChange"
    >
      <swiper-slide
        v-for="(x, index) in viewState.videosCount"
        :key="index"
        :class="'common-swiper-video-items'"
      >
        <commonSwiperVideoItem
          v-if="typeof videos[index] !== 'undefined'"
          :imgIndex="index % 4"
          :class="(index + 1) % 2 == 0 ? 'common-swiper-video-item-mt90' : ''"
          :video="videos[index]"
          :levels="levels"
          :isEnterprise="isEnterprise"
          :isDisplayDocumentExistence="isDisplayDocumentExistence"
        ></commonSwiperVideoItem>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import commonSwiperVideoItem from "./CommonSwiperVideoItem.vue";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Navigation]);
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";

export default defineComponent({
  props: {
    cid: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false,
      default: ""
    },
    videos: {
      type: Array,
      required: true
    },
    levels: {
      type: Array,
      required: false,
      default: () => []
    },
    isEnterprise: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisplayDocumentExistence: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  components: {
    commonSwiperVideoItem,
    Swiper,
    SwiperSlide,
    commonTitle,
  },
  setup(props) {
    const viewState = reactive({
      videosCount: props.videos.length ? props.videos.length : 1,
      realIndex: 1,
    });

    const breakpoints = {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 28,
      },
      766: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 28,
      },
      1036: {
        slidesPerView: 4,
        slidesPerGroup: 2,
        spaceBetween: 28,
      },
      1366: {
        slidesPerView: 4,
        slidesPerGroup: 2,
        spaceBetween: 28,
      },
    };

    // method
    const activeIndexChange = ({ realIndex }) => {
      viewState.realIndex = realIndex + 1;
    };

    return {
      viewState,
      breakpoints,

      // method
      activeIndexChange,
    };
  },
});
</script>
<style lang="scss" scoped>
.common-swiper-video {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1349px;
  width: 100%;
  .common-swiper-video-button-prev {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 175px;
    top: 21px;
    .swiper-button-prev-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      position: relative;
      top: 30px;
      left: 30px;
      right: unset;
      .swiper-button-prev-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .common-swiper-video-button-next {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 115px;
    top: 21px;
    .swiper-button-next-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      position: relative;
      top: 0;
      left: 70px;
      right: unset;
      .swiper-button-next-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .swiper-index {
    display: none;
    @include mq(sm) {
      display: flex;
      height: 15px;
      align-items: baseline;
      position: relative;
      top: -24px;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black-50;
      justify-content: flex-end;
      padding-right: 50px;
    }
  }
  .swiper-container {
    height: 631px;
    margin-left: 65px;
    width: 1284px;
    .common-swiper-video-items {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .common-swiper-video-item-mt90 {
        margin-top: 90px;
      }
    }
    @include mq(sm) {
      margin-top: 15px;
      margin-left: 30px;
      margin-right: 30px;
      width: 275px;
      height: auto;
      .common-swiper-video-items {
        height: 418px;
        .common-swiper-video-item-mt90 {
          margin-top: 0px;
        }
      }
    }
  }
  .common-title {
    &::before {
      top: 0px;
      @include mq(sm) {
        top: 30px;
      }
    }
    ::v-deep(.common-title-area) {
      @include mq(sm) {
        height: unset;
      }
      .common-titles-title {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: $black;
        @include mq(sm) {
          margin-top: 10px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 0.08em;
          color: $black;
          text-align: left;
          margin-right: 50px;
        }
      }
      .common-titles-sub-title {
        width: 500px;
        display: flex;
        justify-content: flex-start;
        line-height: unset;
        @include mq(sm) {
          line-height: 100%;
        }
      }
    }
  }
}
</style>
