<template>
  <div class="common-title">
    <div class="common-title-area">
      <div class="common-titles-title">{{ title }}</div>
      <div v-if="subTitle" class="common-titles-sub-title">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'subTitle'],
}
</script>

<style lang="scss" scoped>
.common-title {
  max-width: 1366px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 10px;
    height: 62px;
    top: 14px;
    background: $primary;
  }
  .common-title-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 65px;
    .common-titles-title {
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 76px;
      line-height: 89px;
      letter-spacing: 0.12em;
      color: $black;
      margin-right: 40px;
    }
    .common-titles-sub-title {
      font-size: 20px;
      line-height: 70px;
      letter-spacing: 0.08em;
      color: $black-50;
    }
  }
  @include mq(sm) {
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 38px;
      top: 34px;
      background: $primary;
    }
    .common-title-area {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-left: 30px;
      height: 78px;
      .common-titles-title {
        margin-top: 10px;
        margin-right: 0;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 47px;
        line-height: 100%;
        letter-spacing: 0.1em;
        color: $black;
      }
      .common-titles-sub-title {
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: $black-50;
      }
    }
  }
}
</style>