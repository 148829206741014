<template>
  <div
    v-if="!plan"
    class="content-header"
    :class="userStore.servicePlanId == 1 ? '' : 'corporate'"
  >
    <slot />
    <icon name="bg-dot-circle" class="layout-bg" />
  </div>
  <div
    v-else
    class="content-header"
    :class="plan == 'corporate' ? 'corporate' : 'premium'"
  >
    <slot />
    <icon name="bg-dot-circle" class="layout-bg" />
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import http from "@/utils/http";
export default defineComponent({
  name: "contentPageHeader",
  components: {},
  props: ["plan"],
  setup() {
    const store = useStore();
    const state = reactive({});
    if (!store.state.user.name && store.state.user.isLogin) {
      http
        .get("/api/users/me")
        .then((res) => {
          store.commit("setName", res.data.name);
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    }

    return {
      state,
      store,
      userStore: store.state.user,
    };
  },
});
</script>
<style lang="scss" scoped>
.content-header {
  height: 528px;
  position: relative;
  padding: 100px 100px 0 100px;
  background-image: url(../assets/img/mypage-personal-header-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  &.corporate {
    background-image: url(../assets/img/mypage-corporate-header-bg.png);
  }
  @include mq(md) {
    height: 775px;
    padding: 80px 50px;
  }
  @include mq(sm) {
    height: 382px;
    background-image: url(../assets/img/mypage-personal-header-bg-sp.png);
    &.corporate {
      background-image: url(../assets/img/mypage-corporate-header-bg-sp.png);
    }
  }
  .layout-bg {
    position: absolute;
    width: 620px;
    height: 620px;
    bottom: -310px;
    left: -310px;
    z-index: -1;
    @include mq(sm) {
      width: 310px;
      height: 310px;
      bottom: -155px;
      left: -155px;
    }
  }
}
</style>