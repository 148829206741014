<template>
  <el-dialog
    v-model="props.menuVisible"
    :lock-scroll="true"
    fullscreen
    width="100%"
    top="0"
    :show-close="false"
    :destroy-on-close="true"
    :modal="false"
    :custom-class="'sp-menu-dialog'"
  >
    <div
      class="sp-menu"
      :class="viewState.close ? 'sp-menu-animation-close' : 'sp-menu-animation-open'"
    >
      <div class="sp-menu-title">
        <div class="sp-menu-text">
          <div class="sp-menu-text1">Menu</div>
          <div class="sp-menu-text2">メニュー</div>
          <div
            class="sp-menu-text3"
            v-if="userStore.isLogin"
            :class="
              userStore.servicePlanId == 1 ? '' : 'sp-menu-text3-corporation'
            "
          >
            {{ userStore.servicePlanId == 1 ? "個人" : "法人" }}
          </div>
        </div>
        <div class="sp-menu-close-btn" @click="closeMenuClick">
          <icon name="sp-menu-close-icon" class="sp-menu-close-icon"></icon>
        </div>
      </div>
      <div class="sp-menu-scroll">
        <div class="sp-menu-list">
          <div
            class="sp-menu-login-items"
            :class="!viewState.expand && 'sp-menu-login-items-expand'"
            v-if="userStore.isLogin"
          >
            <div
              class="user-name"
              :class="
                userStore.isLogin && userStore.hasUnread
                  ? 'unread'
                  : ''
              "
            >
              <icon name="sp-user-name-icon" class="sp-user-name-icon"></icon>
              <icon
                name="sp-user-name-arrow"
                class="sp-user-name-arrow"
                @click="arrowClick"
              ></icon>
            </div>
            <router-link to="/mypage" class="my-page" @click="closeMenuClick">マイページ</router-link>
            <router-link to="/profile/update" class="account"  @click="closeMenuClick">アカウント情報</router-link>
            <div class="notice" @click="noticeClick();closeMenuClick();">
              <div class="notice-text">通知</div>
              <div class="notice-new" v-if="userStore.hasUnread">New</div>
            </div>
            <div class="logout" @click="logoutClick">ログアウト</div>
          </div>
          <div class="sp-menu-items">

            <template
              v-if="isEnterprise"
            >
              <div
                class="sp-menu-item"
                v-for="(item, index) in viewState.enterpriseMenuList"
                :key="index"
                @click="menuClick(item)"
              >
                {{ item.label }}
              </div>
            </template>

            <template
              v-else
            >
              <div
                class="sp-menu-item"
                v-for="(item, index) in viewState.menuList"
                :key="index"
                @click="menuClick(item)"
              >
                {{ item.label }}
              </div>
            </template>

            <template v-if="!userStore.isLogin">
              <div class="sp-menu-login-btn" @click="loginClick">ログイン</div>
              <div class="sp-menu-reg-btn" @click="regClick">新規登録</div>
            </template>
          </div>

        </div>
        <div class="sp-menu-footer">
          <div class="sp-menu-footer-text">
            <template v-if="viewState.nowYear > 2021">
              Copyright (C) IREP Co., Ltd. All Rights Reserved.
            </template>
            <template v-else>
              Copyright (C) IREP Co., Ltd. All Rights Reserved.
            </template>
          </div>
        </div>
      </div>
      <div class="sp-menu-top-svg"></div>
      <div class="sp-menu-bottom-svg">
        <icon name="bg-dot-circle"></icon>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ElDialog } from "element-plus";
import dayjs from "dayjs";
import { useRouter, useRoute } from "vue-router";
import { defineComponent, reactive, computed } from "vue";
import { useStore } from 'vuex'

export default defineComponent({
  name: "sp-menu",
  components: {
    ElDialog,
  },
  props: ["menuVisible"],
  emits: ['closeMenu', 'loginClick', 'logoutClick'],
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const viewState = reactive({
      close: false,
      menuList: [
        { label: "TOP", path: "/" },
        { label: "学習コース", path: "/course" },
        { label: "講座一覧", path: "/lecture" },
        { label: "講師一覧", path: "/teacher" },
        { label: "ジッセン!とは", path: "/about" },
        { label: "FAQ", path: "/faq" },
        { label: "お問い合わせ", path: "/inquiry/input" },
      ],
      enterpriseMenuList: [
        { label: "TOP", path: "/" },
        { label: "学習コース", path: "/course" },
        { label: "講座一覧", path: "/lecture" },
        { label: "お問い合わせ", path: "/inquiry/input" },
      ],
      expand: true,
      nowYear: dayjs().format("YYYY")
    });
    const isEnterprise = computed(() => store.state.user.isEnterprise)

    const noticeClick = () => {
      if (route.name === "mypage") {
        store.dispatch("changeNoticeTab", 1);
      } else {
        router.push("/mypage");
        store.state.user.headerToNoticeTab = true;
      }
    };
    const closeMenuClick = () => {
      context.emit("closeMenu");
    };
    const menuClick = (item) => {
      router.push(item.path);
      context.emit("closeMenu");
    };
    const arrowClick = () => {
      viewState.expand = !viewState.expand;
    };
    const regClick = () => {
      router.push("/apply");
      context.emit("closeMenu");
    };
    const loginClick = () => {
      context.emit("loginClick");
    };
    const logoutClick = () => {
      context.emit("closeMenu");
      context.emit("logoutClick");
    };

    return {
      props,
      viewState,
      store,
      userStore: store.state.user,
      isEnterprise,

      // method
      noticeClick,
      closeMenuClick,
      menuClick,
      arrowClick,
      regClick,
      loginClick,
      logoutClick,
    };
  },
});
</script>

<style lang="scss" scoped>
@keyframes sp-menu-keyframe-open {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes sp-menu-keyframe-close {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.sp-menu-animation-open {
  animation: sp-menu-keyframe-open 0.3s linear 0s 1 forwards;
}
.sp-menu-animation-close {
  animation: sp-menu-keyframe-close 0.3s linear 0s 1 forwards;
}
.sp-menu-dialog {
  .el-dialog__body {
    background: $white;
    padding: 0;
    margin: 0;
    height: 100vh;
    .sp-menu {
      display: none;
      @include mq(sm) {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: $white;
        z-index: 9999;
        opacity: 1;
        overflow: hidden;
        .sp-menu-title {
          height: 60px;
          display: flex;
          z-index: 9999;
          background-color: $white;
          .sp-menu-text {
            flex: 1;
            display: flex;
            border-bottom: 0.5px solid $black-50;
            .sp-menu-text1 {
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 30px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              margin-left: 20px;
              margin-top: 15px;
              white-space: nowrap;
            }
            .sp-menu-text2 {
              margin-left: 10px;
              margin-top: 23px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 300;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.5;
              border: 0.3px solid $white;
              white-space: nowrap;
            }
            .sp-menu-text3 {
              margin-top: 24px;
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              letter-spacing: 0.09em;
              color: $secondary;
            }
            .sp-menu-text3-corporation {
              color: $primary;
            }
          }
          .sp-menu-close-btn {
            width: 60px;
            height: 60px;
            background: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            .sp-menu-close-icon {
              width: 24px;
              height: 24px;
            }
          }
        }
        .sp-menu-scroll {
          overflow-x: hidden;
          overflow-y: auto;
          flex: 1;
          display: flex;
          flex-direction: column;
          .sp-menu-list {
            padding: 60px 60px 80px;
            flex: 1;
            .sp-menu-login-items {
              justify-content: flex-start;
              height: 234px;
              display: flex;
              flex-direction: column;
              border-bottom: 0.5px solid rgba(89, 88, 87, 0.5);
              .user-name {
                display: flex;
                position: relative;
                align-items: center;
                &.unread::before {
                  content: "";
                  position: absolute;
                  top: -5px;
                  left: 18px;
                  background: $secondary;
                  box-shadow: 0px 0px 30px rgba(249, 118, 77, 0.3);
                  width: 8px;
                  height: 8px;
                  border-radius: 8px;
                }
                .sp-user-name-icon {
                  width: 14px;
                  height: 19px;
                }
                .user-name-text {
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 100%;
                  display: flex;
                  align-items: center;
                  letter-spacing: 0.08em;
                  color: $black;
                  margin-left: 26px;
                  white-space: nowrap;
                }
                .sp-user-name-arrow {
                  margin-left: auto;
                  width: 24px;
                  height: 24px;
                  transform: rotate(180deg);
                }
              }
              .my-page {
                margin-top: 33px;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: $black;
              }
              .account {
                margin-top: 30px;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                display: flex;
                align-items: center;
                letter-spacing: 0.08em;
                color: $black;
              }
              .notice {
                margin-top: 30px;
                display: flex;
                .notice-text {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 100%;
                  display: flex;
                  align-items: center;
                  letter-spacing: 0.08em;
                  color: $black;
                }
                .notice-new {
                  margin-left: 10px;
                  font-family: "Roboto Condensed", sans-serif;
                  font-weight: 700;
                  font-size: 12px;
                  line-height: 100%;
                  display: flex;
                  align-items: center;
                  letter-spacing: 0.09em;
                  color: $secondary;
                }
              }
              .logout {
                margin-top: 30px;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.08em;
                color: $black;
              }
            }
            .sp-menu-login-items-expand {
              height: 50px;
              .user-name {
                .sp-user-name-arrow {
                  transform: rotate(0deg);
                }
              }
              .my-page,
              .account,
              .notice,
              .logout {
                display: none;
              }
            }
            .sp-menu-items {
              z-index: 9999;
              .sp-menu-item {
                height: 60px;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: $black;
                border-bottom: 0.5px solid rgba(89, 88, 87, 0.5);
              }
              .sp-menu-login-btn {
                margin-top: 60px;
                height: 60px;
                background: $black;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.08em;
                color: $white;
              }
              .sp-menu-reg-btn {
                margin-top: 30px;
                text-align: center;
                height: 18px;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.08em;
                color: $black;
                white-space: nowrap;
              }
            }
          }
          .sp-menu-footer {
            margin-top: auto;
            background: $black;
            opacity: 0.9;
            .sp-menu-footer-text {
              margin: 25px 50px;
              width: 169px;
              height: 10px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 500;
              font-size: 10px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $white;
              white-space: nowrap;
            }
          }
        }
        .sp-menu-top-svg {
          position: absolute;
          right: -141px;
          top: -81px;
          width: 283px;
          height: 283px;
          background: $grey;
          border-radius: 50%;
          z-index: -1;
        }
        .sp-menu-bottom-svg {
          position: absolute;
          left: -125px;
          bottom: -65px;
          opacity: .3;
          svg {
            width: 250px;
            height: 250px;
          }
        }
      }
    }
  }
}
</style>
