import { reactive } from "vue";

export function videoStore() {
  const state = reactive({
    currentTime: "00:00:00",
    player: null,
  });

  return {
    get currentTime() {
      return state.currentTime;
    },
    get player() {
      return state.player;
    },

    setCurrentTime(string) {
      state.currentTime = string;
    },
    setPlayer(player) {
      state.player = player;
    },
    clearPlayer() {
      state.player = null;
    },
  };
}
