<template>
  <div class="heading">
    <h2 class="hdg">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.heading {
  .hdg {
    font-size: 40px;
    font-weight: 700;
    @include mq(md) {
      font-size: 30px;
    }
  }
}
</style>