import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";
import Top from "../views/top/Top.vue";
import store from '@/store'

const defaultComponent = {
  template: `<router-view />`,
};
const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      title: "home",
    },
    children: [
      {
        path: "/apply",
        name: "apply",
        component: () => import("@/views/apply/apply.vue"),
        meta: {
          title: "新規会員登録",
        },
      },
      {
        path: "/apply/send",
        name: "applySend",
        component: () => import("@/views/apply/send.vue"),
        meta: {
          title: "新規会員登録（仮申込み） メールアドレス入力・送信",
        },
      },
      {
        path: "/apply/send/complete",
        name: "applySendComplete",
        component: () => import("@/views/apply/send-complete.vue"),
        meta: {
          title: "新規会員登録（仮申込み） メール送信完了",
        },
      },
      {
        path: "/apply/input",
        name: "applyInput",
        component: () => import("@/views/apply/input.vue"),
        meta: {
          title: "新規会員登録（本登録） 入力画面",
        },
      },
      {
        path: "/apply/input/confirm",
        redirect: "/",
      },
      {
        path: "/apply/input/complete",
        name: "applyInputComplete",
        component: () => import("@/views/apply/input-complete.vue"),
        meta: {
          title: "新規会員登録（本登録） 完了画面",
        },
      },
      {
        path: "",
        name: "top",
        component: Top,
        meta: {
          title: "TOP",
        },
      },
      {
        path: "/mypage",
        component: defaultComponent,
        meta: {
          title: "マイページ",
        },
        children: [
          {
            path: "",
            name: "mypage",
            component: () => import("@/views/mypage/index.vue"),
          },
        ],
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/faq/index.vue"),
        meta: {
          title: "faq",
        },
      },
      {
        path: "/profile/update",
        name: "profileUpdate",
        component: () => import("@/views/profile-update/index.vue"),
        meta: {
          title: "アカウント情報",
        },
      },
      {
        path: "/profile/update/payment-info",
        name: "paymentInfo",
        component: () => import("@/views/profile-update/paymentInfo.vue"),
        meta: {
          title: "アカウント情報",
        },
      },
      {
        path: "/reset-password/send",
        name: "resetPasswordSend",
        component: () => import("@/views/reset-password/send.vue"),
        meta: {
          title: "パスワードリセット",
        },
      },
      {
        path: "/reset-password/input",
        name: "resetPasswordInput",
        component: () => import("@/views/reset-password/input.vue"),
        meta: {
          title: "パスワードリセット",
        },
      },
      {
        path: "/reset-password/input/complete",
        name: "resetPasswordComplete",
        component: () => import("@/views/reset-password/complete.vue"),
        meta: {
          title: "パスワードリセット",
        },
      },
      {
        path: "/cancel/input",
        name: "cancelInput",
        component: () => import("@/views/cancel/input.vue"),
        meta: {
          title: "解約申請",
        },
      },
      {
        path: "/inquiry/input",
        name: "inquiryInput",
        component: () => import("@/views/inquiry/input.vue"),
        meta: {
          title: "お問い合わせ",
        },
      },
      {
        path: "/inquiry/input/complete",
        name: "inquiryComplete",
        component: () => import("@/views/inquiry/complete.vue"),
        meta: {
          title: "完了ページ",
        },
      },
      {
        path: "/inquiry/business/input",
        name: "inquiryBusinessInput",
        component: () => import("@/views/inquiry/business-input.vue"),
        meta: {
          title: "法人向けライセンスのお問い合わせ",
        },
      },

      {
        path: "/inquiry/business/input/complete",
        name: "inquiryBusinessComplete",
        component: () => import("@/views/inquiry/business-complete.vue"),
        meta: {
          title: "完了ページ",
        },
      },
      {
        path: "/about",
        name: "About",
        component: () => import("@/views/about/index.vue"),
        meta: {
          title: "ジッセン!とは",
        },
      },
      {
        path: "/course",
        component: defaultComponent,
        meta: {
          title: "学習コース",
        },
        children: [
          {
            path: "",
            name: "course",
            component: () => import("@/views/course/course.vue"),
          },
          {
            path: "/course-detail/:courseCode",
            name: "courseDetail",
            meta: {
              title: "コース詳細",
            },
            component: () => import("@/views/course/courseDetail.vue"),
          },
        ],
      },
      {
        path: "/lecture",
        component: defaultComponent,
        meta: {
          title: "講座一覧",
        },
        children: [
          {
            path: "",
            name: "lecture",
            component: () => import("@/views/lecture/lecture.vue"),
          },
          {
            path: "/lecture-detail/:lectureNo",
            name: "lectureDetail",
            meta: {
              title: "講座詳細",
            },
            component: () =>
              import("@/views/lecture-detail/lecture-detail.vue"),
          },
          {
            path: "/view/:lectureCode/:chapterCode/:materialCode",
            name: "view",
            meta: {
              title: "視聴画面",
            },
            component: () => import("@/views/view/view.vue"),
          },
        ],
      },
      {
        path: "/search",
        name: "Search",
        component: () => import("@/views/search/index.vue"),
        meta: {
          title: "検索結果",
        },
      },
      {
        path: "/teacher",
        component: defaultComponent,
        meta: {
          title: "講師一覧",
        },
        children: [
          {
            path: "",
            name: "teacher",
            component: () => import("@/views/teacher/index.vue"),
          },
          {
            path: "/teacher-detail/:teacherCode",
            name: "teacherDetail",
            meta: {
              title: "講師詳細",
            },
            component: () => import("@/views/teacher/detail/index.vue"),
          },
        ],
      },
      {
        path: "/:catchAll(.*)*",
        name: "notfound",
        component: () => import("@/views/NotFound"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_MAINTENANCE_MODE === 'true') {
    store.dispatch("changeIsMaintenance", true);
  }

  next();
});

export default router;
