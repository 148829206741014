<template>
  <div class="commonNavBar">
    <!-- コース詳細から訪問し、講座詳細に訪問した時 -->
    <div
      v-if="store._state.data.courseDetail && $route.name === 'lectureDetail'"
      class="nav-bar"
    >
      <icon name="home" class="home-icon"></icon>
      <div class="nav-bar-item" @click="$router.push('/course')">
        学習コース
      </div>
      <icon name="right-arrow" class="right-arrow"></icon>
      <div
        class="nav-bar-item"
        @click="
          $router.push(`/course-detail/${store._state.data.courseDetailURL}`)
        "
      >
        コース詳細
      </div>
      <icon name="right-arrow" class="right-arrow"></icon>

      <div class="nav-bar-last-item">講座詳細</div>
    </div>
    <!-- コース詳細から訪問し、講座詳細を経て視聴画面に訪問した時 -->
    <div
      v-else-if="store._state.data.courseDetail && $route.name === 'view'"
      class="nav-bar"
    >
      <icon name="home" class="home-icon"></icon>
      <div class="nav-bar-item" @click="$router.push('/course')">
        学習コース
      </div>
      <icon name="right-arrow" class="right-arrow"></icon>
      <div class="nav-bar-item" @click="$router.go(-2)">コース詳細</div>
      <icon name="right-arrow" class="right-arrow"></icon>
      <div class="nav-bar-item" @click="$router.go(-1)">講座詳細</div>
      <icon name="right-arrow" class="right-arrow"></icon>
      <div class="nav-bar-last-item">視聴画面</div>
    </div>
    <!-- それ以外 -->
    <div v-else class="nav-bar">
      <a
        v-for="(item, index) in list"
        :key="index"
        @click="
          navBarClick(item.path, index === 0 || index !== list.length - 1)
        "
      >
        <template v-if="item.meta && item.meta.title">
          <icon
            name="home"
            class="home-icon"
            v-if="item.meta.title == 'home'"
          ></icon>
          <template v-else>
            <div
              class="nav-bar-item"
              :class="index == list.length - 1 ? 'nav-bar-last-item' : ''"
            >
              {{ item.meta.title }}
            </div>
            <icon
              name="right-arrow"
              class="right-arrow"
              v-if="index < list.length - 1"
            ></icon>
          </template>
        </template>
      </a>
    </div>
    <div class="nav-bar-mask"></div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { reactive, toRefs, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      list: [],
    });

    //onMounted
    onMounted(() => {
      let matched = route.matched;
      state.list = matched.filter((item) => {
        return item && item.meta && item.meta.title;
      });
    });

    //watch
    watch(
      () => route.matched,
      (newVal) => {
        let matched = newVal;
        state.list = matched.filter((item) => {
          return item && item.meta && item.meta.title;
        });
      }
    );

    //return
    return {
      store,
      ...toRefs(state),
    };
  },
  methods: {
    navBarClick(path, movePage) {
      movePage && this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.commonNavBar {
  // height: 80px;
  background-color: white;
  border-bottom: 0.5px solid $black-50;
  .nav-bar {
    margin: 0 auto;
    padding: 0 50px;
    max-width: 1366px;
    // border-top: 0.5px solid $black-50;
    display: flex;
    flex-wrap: wrap;
    a {
      display: flex;
      text-decoration: none;
    }
    .home-icon {
      cursor: pointer;
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      margin-top: 20px;
      margin-right: 25px;
      margin-bottom: 20px;
    }
    .nav-bar-item {
      cursor: pointer;
      margin-top: 30px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.08em;
      color: $black;
      margin-right: 21px;
      white-space: nowrap;
    }
    .nav-bar-last-item {
      margin-top: 29px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.08em;
      color: $black;
      white-space: nowrap;
      cursor: default;
    }
    .right-arrow {
      margin-right: 20px;
      margin-top: 36px;
      min-width: 10px;
      min-height: 10px;
      width: 10px;
      height: 10px;
    }
  }
  .nav-bar-mask {
    display: none;
  }
}
@include mq(sm) {
  .commonNavBar {
    height: 60px;
    border-bottom: 0.5px solid $black-50;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .nav-bar {
      position: relative;
      height: 60px;
      border-top: none;
      margin-left: 0px;
      margin-right: 0px;
      padding: 0 40px 0 0;
      flex-wrap: nowrap;
      .home-icon {
        min-width: 32px;
        min-height: 32px;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        margin-top: 14px;
        margin-right: 15px;
      }
      .nav-bar-item {
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: $black;
        margin-right: 11px;
        margin-top: 24px;
        white-space: nowrap;
      }
      .nav-bar-last-item {
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: $black;
        margin-top: 23px;
        white-space: nowrap;
      }
      .right-arrow {
        margin-right: 11px;
        margin-top: 25px;
        min-width: 10px;
        min-height: 10px;
        width: 10px;
        height: 10px;
      }
    }
    .nav-bar-mask {
      position: absolute;
      display: block;
      right: 0;
      width: 38px;
      height: 58px;
      background: linear-gradient(
        270deg,
        $white 0%,
        $white 50%,
        rgba(255, 255, 255, 0.75) 75%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}
</style>
