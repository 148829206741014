<template>
  <div
    class="popular-learning-item"
    :style="{
      'background-image': isSp ? `url(${spBgImgSrc})` : '',
      'background-size': 'cover',
    }"
    ref="popularLearningItem"
    @mouseover="itemMouseover(this)"
    @mouseleave="itemMouseleave(this)"
  >
    <div
      class="item-top-img"
      :style="{
        'background-image': `url(${course.image_url})`
      }"
      @click="clickViewDetail"
    ></div>
    <div class="item-title">{{ course.name }}</div>
    <div class="item-subtitle">
      {{ course.overview }}
    </div>
    <div class="item-divider"></div>
    <div class="item-detail">
      <template v-if="isEnterprise">
        <div class="enterprise-item-detail-item">
          <div class="item-detail-item-top">講座数</div>
          <div class="item-detail-item-bottom">{{ course.lectures_count }}</div>
        </div>
        <div class="enterprise-item-detail-item">
          <div class="item-detail-item-top">公開日数</div>
          <div
            class="item-detail-item-bottom"
            :class="course.remaining_days !== null && course.remaining_days < 10 ? 'expire-soon' : ''"
          >
            <template v-if="course.remaining_days">
              残り{{ course.remaining_days }}日
            </template>

            <template v-else>無制限</template>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="item-detail-item">
          <div class="item-detail-item-top">レベル</div>
          <div class="item-detail-item-bottom">{{ displayLevel }}</div>
        </div>
        <div class="item-detail-item">
          <div class="item-detail-item-top">講座</div>
          <div class="item-detail-item-bottom">{{ course.lecture_count }} </div>
        </div>
        <div class="item-detail-item">
          <div class="item-detail-item-top">視聴時間</div>
          <div class="item-detail-item-bottom">{{ totalAttendanceTimeHm }}</div>
        </div>
      </template>
    </div>
    <div
      class="item-btn"
      @click="clickViewDetail"
    >
      <div class="item-btn-text">コース詳細を見る</div>
      <icon name="chapter-detail-btn" class="item-btn-icon"></icon>
    </div>
  </div>
</template>

<script >
import display from "@/utils/display";
import { defineComponent, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    'imgIndex': {},
    'course': {
      default: {
        name: "",
        overview: "",
        lecture_level_ids: [],
      },
    },
    levels: {
      type: Array,
      required: false,
      default: () => []
    },
    isEnterprise: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const viewState = reactive({
    });

    const isSp = computed(() => store.state.isSp);
    const totalAttendanceTimeHm = computed(() => display.timestampToHm(props['course'].total_attendance_time));
    const bgImgSrc = computed(() => require(`../../../assets/img/course-hover-bg.png`));
    const spBgImgSrc = computed(() => require(`../../../assets/img/course-sp-bg.png`));
    const displayLevel = computed(() => {
      if (props['levels'].length <= 0 || props['course'].lecture_level_ids.length <= 0) {
        return "";
      } else if (props['course'].lecture_level_ids.length > 1) {
        return `${props['levels'][props['course'].lecture_level_ids[0]].name}〜`;
      } else {
        return props['levels'][props['course'].lecture_level_ids[0]].name;
      }
    })

    // method
    const clickViewDetail = () => {
      router.push(`/course-detail/${props['course'].code}`)
    }
    const itemMouseover = (e) => {
      if (isSp.value) return
      e.$el.style.background = `url(${bgImgSrc.value})`
      e.$el.style.backgroundSize = '100%'
    };
    const itemMouseleave = (e) => {
      if (isSp.value) return
      e.$el.style.background = ``
    };

    return {
      viewState,
      isSp,
      totalAttendanceTimeHm,
      bgImgSrc,
      spBgImgSrc,
      displayLevel,

      // method
      clickViewDetail,
      itemMouseover,
      itemMouseleave,
    };
  },
});
</script>

<style lang="scss" scoped>
.popular-learning-item {
  width: 360px;
  height: 598px;
  @include mq(sm) {
    background-size: 100%;
    width: 275px;
    height: 573px;
    filter: drop-shadow(0px 0px 45px rgba(0, 0, 0, 0.1));
  }
  .item-top-img {
    cursor: pointer;
    background-size: 100%;
    width: 360px;
    height: 300px;
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    background-size: cover;
    @include mq(sm) {
      width: 275px;
      height: 235px;
      border: 0.5px solid rgba(0, 0, 0, 0.5);
    }
  }
  .item-title {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.1em;
    color: $black;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include mq(sm) {
      white-space: pre-wrap;
      text-overflow: ellipsis;
      margin-top: 30px;
      margin-left: 20px;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $black;
    }
  }
  .item-subtitle {
    width: 326px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: $black;
    opacity: 0.8;
    text-align: left;
    @include mq(sm) {
      height: 60px;
      margin-bottom: 30px;
      width: 235px;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $black;
      opacity: 0.8;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .item-divider {
    margin-top: 30px;
    width: 360px;
    height: 0px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  }
  .item-detail {
    width: 360px;
    height: 80px;
    display: flex;
    @include mq(sm) {
      width: 573px;
      height: 78px;
    }
    .item-detail-item:nth-child(1) {
      width: 106px;
      @include mq(sm) {
        width: 96px;
      }
    }
    .item-detail-item:nth-child(2) {
      width: 132px;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 30px;
        width: 0px;
        height: 20px;
        border-left: 0.5px solid $black-50;
      }
      @include mq(sm) {
        width: 90px;
        &::before {
          height: 15px;
          top: 31.5px;
        }
      }
    }
    .item-detail-item:nth-child(3) {
      width: 121px;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 30px;
        width: 0px;
        height: 20px;
        border-left: 0.5px solid $black-50;
      }
      @include mq(sm) {
        width: 89px;
        &::before {
          height: 15px;
          top: 31.5px;
        }
      }
    }
    .item-detail-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-detail-item-top {
        margin-top: 16px;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.09em;
        color: rgba(89, 88, 87, 0.8);
        @include mq(sm) {
          margin-top: 20px;
          font-weight: 500;
          font-size: 10px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.09em;
          color: $black;
        }
      }
      .item-detail-item-bottom {
        margin-top: 3px;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.09em;
        color: $black;
        @include mq(sm) {
          margin-top: 10px;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.09em;
          color: $black;
        }
      }
    }

    .enterprise-item-detail-item:nth-child(1) {
      width: 179.5px;
      @include mq(sm) {
        width: 141px;
      }
    }
    .enterprise-item-detail-item:nth-child(2) {
      width: 179.5px;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 30px;
        width: 0px;
        height: 20px;
        border-left: 0.5px solid $black-50;
      }
      @include mq(sm) {
        width: 134px;
        &::before {
          height: 15px;
          top: 31.5px;
        }
      }
    }
    .enterprise-item-detail-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-detail-item-top {
        margin-top: 16px;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.09em;
        color: rgba(89, 88, 87, 0.8);
        @include mq(sm) {
          margin-top: 20px;
          font-weight: 500;
          font-size: 10px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.09em;
          color: $black;
        }
      }
      .item-detail-item-bottom {
        margin-top: 3px;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.09em;
        color: $black;
        @include mq(sm) {
          margin-top: 10px;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.09em;
          color: $black;
        }
        
        &.expire-soon {
          color: $red;
        }
      }
    }
  }
  .item-btn {
    cursor: pointer;
    width: 360px;
    height: 60px;
    background: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq(sm) {
      width: 275px;
      height: 60px;
    }
    .item-btn-text {
      margin-left: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $white;
      z-index: 5;
      @include mq(sm) {
        margin-left: 20px;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $white;
      }
    }
    .item-btn-icon {
      height: 10px;
      width: 40px;
      margin-right: 15px;
      z-index: 5;
      @include mq(sm) {
        margin-right: 20px;
      }
    }
  }
  &:hover {
    .item-top-img {
      border: 0.5px solid rgba(0, 0, 0, 0.5);
    }
    .item-btn {
      @keyframes popular-learning-item-keyframe {
        from {
          width: 0px;
        }
        to {
          width: 360px;
        }
      }
      &:hover {
        &::before {
          content: '';
          left: 0;
          height: 60px;
          position: absolute;
          background: $primary;
          z-index: 0;
          animation: popular-learning-item-keyframe 0.3s linear 0s 1 forwards;
          @include mq(sm) {
            animation: unset;
          }
        }
        &::after {
          content: '';
          left: 0;
          height: 60px;
          position: absolute;
          background: $black;
          z-index: 1;
          animation: popular-learning-item-keyframe 0.3s linear 0.6s 1 forwards;
          @include mq(sm) {
            animation: unset;
          }
        }
      }
    }
  }
}
</style>