<template>
  <div class="page-top" v-if="viewState.isDisplayed">
    <!-- エンタープライズ用 -->
    <template
      v-if="isLogin && isEnterprise"
    >
      <div class="login">
        <div class="login-top-bg"></div>

        <!-- 大バナー -->
        <template v-if="viewState.largeBanners.length > 0">
          <div class="large-banner">
            <template v-if="viewState.largeBanners.length === 1">
              <div>
                <img
                  v-if="viewState.largeBanners[0].link_url"
                  :src="viewState.largeBanners[0].image_url"
                  @click="clickBanner(viewState.largeBanners[0].link_url, viewState.largeBanners[0].is_target_blank)"
                  class="banner-link"
                >
                <img
                  v-else
                  :src="viewState.largeBanners[0].image_url"
                >
              </div>
            </template>
            <template v-else>
              <carousel
                :mouseDrag="false"
                ref="largeBannerCarousel"
              >
                <carousel-slide v-for="largeBanner in viewState.largeBanners" :key="largeBanner.id">
                  <img
                    v-if="largeBanner.link_url"
                    :src="largeBanner.image_url"
                    @click="clickBanner(largeBanner.link_url, largeBanner.is_target_blank)"
                    class="banner-link"
                  >
                  <img
                    v-else
                    :src="largeBanner.image_url"
                  >
                </carousel-slide>

                <template #addons="{ slidesCount }">
                  <carousel-navigation v-if="slidesCount > 1" />
                  <carousel-pagination v-if="slidesCount > 1" />
                </template>
              </carousel>
            </template>
          </div>
        </template>

        <!-- 小バナー -->
        <template v-if="viewState.smallBanners.length > 0">
          <div class="small-banner">
            <template v-if="viewState.smallBanners.length === 1">
              <div>
                  <img
                    v-if="viewState.smallBanners[0].link_url"
                    :src="viewState.smallBanners[0].image_url"
                    @click="clickBanner(viewState.smallBanners[0].link_url, viewState.smallBanners[0].is_target_blank)"
                    class="banner-link"
                  >
                  <img
                    v-else
                    :src="viewState.smallBanners[0].image_url"
                  >
              </div>
            </template>
            <template v-else>
              <carousel
                :mouseDrag="false"
                ref="smallBannerCarousel"
              >
                <carousel-slide v-for="smallBanner in viewState.smallBanners" :key="smallBanner.id">
                  <img
                    v-if="smallBanner.link_url"
                    :src="smallBanner.image_url"
                    @click="clickBanner(smallBanner.link_url, smallBanner.is_target_blank)"
                    class="banner-link"
                  >
                  <img
                    v-else
                    :src="smallBanner.image_url"
                  >
                </carousel-slide>

                <template #addons="{ slidesCount }">
                  <carousel-navigation v-if="slidesCount > 1" />
                  <carousel-pagination v-if="slidesCount > 1" />
                </template>
              </carousel>
            </template>
          </div>
        </template>

        <div class="top-login-top-bg-2">
          <icon name="bg-dot-circle" class="pc-bg2"></icon>
          <icon name="bg-dot-circle" class="sp-bg2"></icon>
        </div>

        <template v-if="viewState.customCourses.length > 0">
          <popularLearning
            :title="'LEARNING COURSES'"
            :subTitle="'学習コース'"
            :courses="viewState.customCourses"
            :isEnterprise="isEnterprise"
          />
        </template>

        <template v-if="viewState.requiredLectures.length > 0">
          <div class="top-login-required">
            <commonSwiperVideo
              :cid="5"
              :title="'REQUIRED LESSONS'"
              :subTitle="'必修講座'"
              :videos="viewState.requiredLectures"
              :isEnterprise="isEnterprise"
              :isDisplayDocumentExistence="true"
            />
          </div>
          <div class="partition-line"></div>
        </template>
        
        <template v-if="viewState.attendedLectures.length > 0">
          <div class="top-login-watch-again">
            <watchSwiper
              :cid="6"
              :title="'WATCH AGAIN'"
              :subTitle="'もう1度見る'"
              :buttonText="'講座詳細を見る'"
              :videos="viewState.attendedLectures"
            />
          </div>
          <div class="partition-line"></div>
        </template>

        <div
          v-if="viewState.requiredLectures.length > 0 && viewState.attendedLectures.length > 0 && viewState.newestLectures.length > 0"
          class="login-bottom-bg"
        >
        </div>
      </div>
    </template>

    <!-- プレミアム・ビジネス -->
    <template
      v-else-if="
        isLogin &&
          (viewState.attendingLectures.length > 0 ||
            viewState.attendedLectures.length > 0)
      "
    >
      <div
        class="login"
      >
        <div class="login-top-bg"></div>

        <div class="top-login-top-bg-2">
          <icon name="bg-dot-circle" class="pc-bg2"></icon>
          <icon name="bg-dot-circle" class="sp-bg2"></icon>
        </div>
        <template v-if="viewState.attendingLectures.length > 0">
          <div class="top-login-currently-watching">
            <watchSwiper
              :cid="1"
              :title="'CURRENTLY WATCHING'"
              :subTitle="'受講中の講座'"
              :buttonText="'受講を開始する'"
              :videos="viewState.attendingLectures"
            />
          </div>
          <div class="partition-line"></div>
        </template>

        <template v-if="viewState.attendedLectures.length > 0">
          <div class="top-login-watch-again">
            <watchSwiper
              :cid="2"
              :title="'WATCH AGAIN'"
              :subTitle="'もう1度見る'"
              :buttonText="'講座詳細を見る'"
              :videos="viewState.attendedLectures"
            />
          </div>
          <div class="partition-line"></div>
        </template>
        <div class="login-bottom-bg"></div>
      </div>
    </template>

    <!-- 未ログイン -->
    <template
      v-else
    >
      <div class="nologin">
        <div class="top-bg1"></div>
        <div class="top-bg2"></div>
        <div class="top-bg3"></div>
        <div class="top-bg4"></div>
        <div class="top-bg5">
          <icon name="top-bg5" class="top-bg5-icon"></icon>
          <icon name="sp-top-bg5" class="sp-top-bg5-icon"></icon>
        </div>
        <div class="nologin-title">
          デジタル時代に求められる最も実践的な<br />
          DX専門オンラインスクール
        </div>
        <div class="nologin-center-img">
          <div class="nologin-center-img-pc" />
          <div class="nologin-center-img-sp"></div>
        </div>
      </div>
    </template>

    <template v-if="viewState.isDisplayed">
      <!-- エンタープライズ -->
      <template
        v-if="isEnterprise"
      >
        <div class="top-center">
          <div class="top-center-bg">
            <icon name="bg-dot-circle" class="pc-bg2"></icon>
            <icon name="bg-dot-circle" class="sp-bg2"></icon>
          </div>
          <div class="top-new-lessons">
            <commonSwiperVideo
              :cid="7"
              :title="'NEW LESSONS'"
              :subTitle="'新着講座'"
              :videos="viewState.newestLectures"
              :isEnterprise="isEnterprise"
            />
          </div>
        </div>
      </template>

      <!-- プレミアム・ビジネス・未ログイン -->
      <template
        v-else
      >
        <popularLearning
          :title="'POPULAR&nbsp;LEARNING COURSES'"
          :subTitle="'人気の学習コース'"
          :courses="viewState.popularCourses"
          :levels="viewState.levels"
        >
        </popularLearning>

        <div class="top-center">
          <div class="top-center-bg">
            <icon name="bg-dot-circle" class="pc-bg2"></icon>
            <icon name="bg-dot-circle" class="sp-bg2"></icon>
          </div>
          <div class="top-popular-lessons">
            <commonSwiperVideo
              :cid="3"
              :title="'POPULAR LESSONS'"
              :subTitle="'人気の講座'"
              :videos="viewState.popularLectures"
              :levels="viewState.levels"
            />
          </div>
          <bottomBtn :label="'講座一覧を見る'" :routeName="'lecture'" />
          <div class="top-new-lessons">
            <commonSwiperVideo
              :cid="4"
              :title="'NEW LESSONS'"
              :subTitle="'新着講座'"
              :videos="viewState.newestLectures"
              :levels="viewState.levels"
            />
          </div>
          <bottomBtn :label="'講座一覧を見る'" :routeName="'lecture'" />
        </div>
        <div class="top-bottom">
          <div class="top-bottom-top-bg"></div>
          <div class="top-bottom-bottom-bg"></div>
          <div class="top-bottom-recommended-topics">
            <div class="top-bottom-recommended-topics-title">
              RECOMMENDED JOB ROLES
            </div>
            <div class="top-bottom-recommended-topics-subtitle">
              オススメのジョブロール
            </div>
            <div class="top-bottom-content">
              <div
                class="top-bottom-content-item"
                v-for="jobRole in jobRoleList"
                :key="jobRole.id"
                @click="clickJobRole(jobRole.id)"
              >
                {{ jobRole.name }}
              </div>
            </div>
          </div>
          <div
            v-if="viewState.jobRoles.length > 5"
            class="top-bottom-recommended-topics-more"
            @click="clickMore"
          >
            <template v-if="!viewState.isMoreList">
              <div class="top-bottom-recommended-topics-more-text">
                もっと見る
              </div>
              <icon name="sp-key-word-more-btn"></icon>
            </template>
            <template v-else>
              <div class="top-bottom-recommended-topics-more-text">閉じる</div>
              <icon name="sp-key-word-less-btn"></icon>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import util from "@/utils/util";
import http from "@/utils/http";
import popularLearning from "./components/PopularLearning.vue";
import watchSwiper from "./components/WatchSwiper.vue";
import bottomBtn from "./components/BottomBtn.vue";
import commonSwiperVideo from "@/components/CommonSwiperVideo/CommonSwiperVideo.vue";
import { ElLoading } from "element-plus";
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Slide as CarouselSlide,
  Pagination as CarouselPagination,
  Navigation as CarouselNavigation
} from 'vue3-carousel';
import {
  defineComponent,
  reactive,
  onMounted,
  computed,
  watch,
  ref,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {
    popularLearning,
    watchSwiper,
    bottomBtn,
    commonSwiperVideo,
    Carousel,
    CarouselSlide,
    CarouselPagination,
    CarouselNavigation
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const viewState = reactive({
      response: {
        status: 0,
        data: {},
      },
      isDisplayed: false,
      list: 15,
      isMoreList: false,
      popularLectures: {},
      newestLectures: {},
      attendingLectures: {},
      attendedLectures: {},
      requiredLectures: {},
      popularCourses: {},
      levels: [],
      jobRoles: [],
      largeBanners: [],
      smallBanners: [],
      customCourses: [],
    });
    const largeBannerCarousel = ref(null);

    window.scrollTo(0, 0);

    const isLogin = computed(() => store.state.user.isLogin);
    const isSp = computed(() => store.state.isSp);
    const isEnterprise = computed(() => store.state.user.isEnterprise);

    const jobRoleList = computed(() => {
      if (isSp.value) {
        return viewState.jobRoles.slice(
          0,
          viewState.isMoreList ? viewState.jobRoles.length : 5
        );
      } else {
        return viewState.jobRoles;
      }
    });

    // method
    const clickMore = () => {
      viewState.isMoreList = !viewState.isMoreList;
    };
    const clickJobRole = (id) => {
      router.push({
        name: "lecture",
        hash: `#${id}`,
      });
    };
    const clickBanner = (url, isTargetBlank) => {
      if (isTargetBlank) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    };
    const fetchLevels = () => {
      return http
        .get("/api/levels")
        .then((response) => {
          viewState.levels = util.keyBy(response.data, "id");
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchJobRoles = () => {
      return http
        .get("/api/job-roles")
        .then((response) => {
          // 講座が紐付いているジョブロールのみ対象とする
          viewState.jobRoles = response.data.filter(
            (jobRole) => jobRole.lectures_count > 0
          );
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchPopularLectures = async () => {
      return await http
        .get(`/api/lectures/?order=2&count=80`)
        .then((response) => {
          console.dir(response.data);
          viewState.popularLectures = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchNewestLectures = async () => {
      return await http
        .get(`/api/lectures/?order=1&count=80`)
        .then((response) => {
          console.dir(response.data);
          viewState.newestLectures = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchAttendingLectures = async () => {
      return await http
        .get(`/api/lectures?status=2&res_ptn=1&order=3&count=80`)
        .then((response) => {
          console.dir(response.data);
          viewState.attendingLectures = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchAttendedLectures = async () => {
      return await http
        .get(`/api/lectures/?status=3&res_ptn=1&order=3&count=80`)
        .then((response) => {
          console.dir(response.data);
          viewState.attendedLectures = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchRequiredLecturesForEnterprise = async () => {
      return await http
        .get(`/api/enterprise-lectures/?attendance_type=1&order=3&count=80`)
        .then((response) => {
          console.dir(response.data);
          viewState.requiredLectures = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchAttendedLecturesForEnterprise = async () => {
      return await http
        .get(`/api/enterprise-lectures/?status=3&order=2&count=80`)
        .then((response) => {
          console.dir(response.data);
          viewState.attendedLectures = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchNewestLecturesForEnterprise = async () => {
      return await http
        .get(`/api/enterprise-lectures/?order=1&count=80`)
        .then((response) => {
          console.dir(response.data);
          viewState.newestLectures = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchLargeBanners = async () => {
      return await http
        .get(`/api/banners/?type=1`)
        .then((response) => {
          console.dir(response.data);
          viewState.largeBanners = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchSmallBanners = async () => {
      return await http
        .get(`/api/banners/?type=2`)
        .then((response) => {
          console.dir(response.data);
          viewState.smallBanners = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchPopularCourses = async () => {
      return await http
        .get(`/api/courses?order=2&count=30`)
        .then((response) => {
          console.dir(response.data);
          viewState.popularCourses = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchCustomCourses = async () => {
      return await http
        .get(`/api/custom-courses`)
        .then((response) => {
          console.dir(response.data);
          viewState.customCourses = response.data;

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const reInit = async () => {
      console.log("re-init");

      if (isEnterprise.value) {
        await fetchRequiredLecturesForEnterprise(); // 必須講座
        await fetchAttendedLecturesForEnterprise(); // もう1度見る
        await fetchNewestLecturesForEnterprise(); // 新着講座
        await fetchCustomCourses(); // カスタムコース
        await fetchLargeBanners(); // 大バナー
        await fetchSmallBanners(); // 小バナー
      } else {
        fetchLevels();
        fetchJobRoles();
        await fetchAttendingLectures();
        await fetchAttendedLectures();
        await fetchPopularCourses();
        await fetchPopularLectures(viewState.courseCode);
        await fetchNewestLectures(viewState.courseCode)
      }
      viewState.isDisplayed = true; // 表示OK
    };

    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで講座情報を取得する
        console.log("skip on-mount process");
        return;
      }

      if (isEnterprise.value) {
        await fetchRequiredLecturesForEnterprise(); // 必須講座
        await fetchAttendedLecturesForEnterprise(); // もう1度見る
        await fetchNewestLecturesForEnterprise(); // 新着講座
        await fetchCustomCourses(); // カスタムコース
        await fetchLargeBanners(); // 大バナー
        await fetchSmallBanners(); // 小バナー
      } else {
        fetchLevels();
        fetchJobRoles();

        if (isLogin.value) {
          // ログイン中のみ、受講中の講座や受講履歴を見る
          await fetchAttendingLectures();
          await fetchAttendedLectures();
        }

        // 動画取得失敗時、現在は遷移先が無いため、何もしない
        await fetchPopularCourses();
        await fetchPopularLectures(viewState.courseCode);
        await fetchNewestLectures(viewState.courseCode);
      }

      viewState.isDisplayed = true; // 表示OK
    });
    const loading = ElLoading.service({
      lock: true,
      text: "Loading...",
      background: "#fff",
    });
    watch(
      () => viewState.isDisplayed,
      () => {
        loading.close();
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );
    return {
      util,
      viewState,
      isLogin,
      isEnterprise,
      jobRoleList,
      largeBannerCarousel,
      // method
      clickMore,
      clickJobRole,
      clickBanner,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-top {
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  margin: 0 auto;
  .login {
    max-width: 1366px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mq(sm) {
      width: 375px;
    }
    .login-top-bg {
      max-width: 1368px;
      width: 1368px;
      min-height: 50px;
      background-image: url("../../assets/img/top-login-top-bg.png");
      background-size: 100%;
      z-index: 1;
      @include mq(sm) {
        width: 375px;
        background-image: url("../../assets/img/sp-top-bg.png");
      }
    }
    .top-login-top-bg-2 {
      position: absolute;
      width: 620px;
      height: 620px;
      left: -310px;
      top: -280px;
      z-index: -1;
      @include mq(sm) {
        left: -141px;
        top: -91px;
      }
      .pc-bg2 {
        min-width: 620px;
        min-height: 620px;
        @include mq(sm) {
          display: none;
        }
      }
      .sp-bg2 {
        display: none;
        @include mq(sm) {
          display: block;
          width: 282px;
          height: 282px;
        }
      }
    }

    // banner
    .large-banner {
      margin: 65px 50px 58.4px 50px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      position: relative;
      @include mq(sm) {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 40px;
      }

      .carousel, &>div {
        max-width: 1266px;
        height: 500px;

        @include mq(sm) {
          width: 335px;
          height: 165px;
        }
      }

      img {
        max-width: 1266px;
        max-height: 500px;
        width: 100%;
        height: 100%;
        object-fit: fill;

        @include mq(sm) {
          max-width: 335px;
          max-height: 165px;
        }

        &.banner-link {
          cursor: pointer;
        }
      }

      &+.small-banner {
        margin-top: 0px;
      }
    }
    .small-banner {
      margin: 65px 50px 58.4px 50px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      position: relative;
      @include mq(sm) {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 40px;
        width: 230px;
        height: 90px;
      }


      .carousel, &>div {
        max-width: 230px;
        height: 90px;
      }

      img {
        max-width: 230px;
        max-height: 90px;
        width: 100%;
        height: 100%;
        object-fit: fill;

        &.banner-link {
          cursor: pointer;
        }
      }
    }

    .top-login-required {
      padding-left: 50px;
      padding-top: 66px;
      width: 100%;
      height: 786px;
      @include mq(sm) {
        padding-left: 20px;
        padding-top: 60px;
        height: 696px;
      }
      @media screen and (max-width: 650px) {
        .common-titles-title {
          white-space: normal !important;
          width: 170px !important;
        }
      }
      @include mq(sm) {
        .common-titles-title {
          white-space: normal !important;
          width: unset !important;
        }
      }
    }
    .top-login-currently-watching {
      height: 786px;
      padding-left: 50px;
      padding-top: 65px;
      max-width: 1366px;
      width: 100%;
      @include mq(sm) {
        padding-left: 20px;
        padding-top: 60px;
        padding-right: 50px;
        width: 375px;
        height: 812px;
      }
    }
    .top-login-watch-again {
      height: 786px;
      padding-left: 50px;
      padding-top: 66px;
      max-width: 1366px;
      width: 100%;
      @include mq(sm) {
        padding-left: 20px;
        padding-top: 60px;
        padding-right: 50px;
        width: 375px;
      }
    }
    .partition-line {
      max-width: 1366px;
      width: calc(100% - 230px);
      border-bottom: 0.5px solid $black-50;
      @include mq(sm) {
        width: 275px;
      }
    }
    .login-bottom-bg {
      position: absolute;
      width: 584px;
      height: 584px;
      background: $grey;
      border-radius: 50%;
      top: 1620px;
      right: -289px;
      z-index: -1;
      @include mq(sm) {
        top: 1769px;
        right: -127px;
        width: 255px;
        height: 255px;
      }
    }
  }
  .nologin {
    max-width: 1366px;
    width: 100%;
    position: relative;
    .top-bg1 {
      width: 70px;
      height: 70px;
      background: $primary;
      opacity: 0.8;
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      left: 189px;
      top: -30px;
      @include mq(sm) {
        width: 40px;
        height: 40px;
        background: $primary;
        opacity: 0.8;
        border-radius: 50%;
        left: 75px;
        top: -15px;
      }
    }
    .top-bg2 {
      background: $secondary;
      opacity: 0.4;
      border-radius: 50%;
      width: 143px;
      height: 143px;
      position: absolute;
      z-index: -1;
      top: 40px;
      left: 50px;
      @include mq(sm) {
        width: 100px;
        height: 100px;
        left: -10px;
        top: 23px;
        background: $secondary;
        opacity: 0.4;
        border-radius: 50%;
      }
    }
    .top-bg3 {
      width: 584px;
      height: 584px;
      background: $grey;
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      right: -296px;
      top: -292px;
      @include mq(sm) {
        width: 255px;
        height: 255px;
        right: -127px;
        top: -127px;
      }
    }
    .top-bg4 {
      width: 1316px;
      height: 772px;
      background-image: url("../../assets/img/top-bg4.png");
      position: absolute;
      top: 417px;
      left: 50px;
      z-index: -1;
      @include mq(sm) {
        background-image: url("../../assets/img/sp-top-bg4.png");
        top: 298px;
        left: 20px;
        right: 0;
        width: auto;
        height: 272px;
        background-size: cover;
        background-position: center;
      }
    }
    .top-bg5 {
      position: absolute;
      z-index: 1;
      top: 725px;
      right: 50px;
      @include mq(sm) {
        top: 390px;
        right: 0;
      }
      .top-bg5-icon {
        width: 1267px;
        height: 202px;
        @include mq(sm) {
          display: none;
        }
      }
      .sp-top-bg5-icon {
        width: 355px;
        height: 131px;
        display: none;
        @include mq(sm) {
          display: block;
        }
      }
    }
    .nologin-title {
      margin-left: 115px;
      margin-top: 100px;
      margin-right: 100px;
      max-width: 614px;
      height: 110px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 26px;
      line-height: 210%;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      color: $black;
      text-align: left;
      @include mq(sm) {
        margin-left: 50px;
        margin-top: 80px;
        margin-right: 0px;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 45px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: $black;
        width: 275px;
        height: 135px;
      }
    }
    .nologin-center-img {
      margin-left: 50px;
      margin-top: 65px;
      max-width: 1266px;
      height: 500px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      @include mq(sm) {
        margin-left: 20px;
        margin-top: 40px;
        width: 335px;
        height: 165px;
      }
      .mask {
        opacity: 0.05;
        width: 100%;
        height: 500px;
        background: $primary;
        z-index: 4;
      }
      .nologin-center-img-pc {
        position: relative;
        width: 100%;
        padding-top: 39.49%;
        z-index: 3;
        object-fit: contain;
        background-image: url("../../assets/img/top-nologin-center-img.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        @include mq(sm) {
          display: none;
        }
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(54, 131, 245, 0.05);
        }
      }
      .nologin-center-img-sp {
        display: none;
        z-index: 3;
        @include mq(sm) {
          position: relative;
          background-image: url("../../assets/img/top-nologin-center-img.png");
          display: block;
          width: 335px;
          height: 165px;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(54, 131, 245, 0.05);
          }
        }
      }
    }
  }
  .top-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1366px;
    width: 100%;
    position: relative;
    .top-center-bg {
      position: absolute;
      width: 620px;
      height: 620px;
      left: -310px;
      top: -308px;
      z-index: -1;
      @include mq(sm) {
        left: -140px;
        top: -140px;
      }
      .pc-bg2 {
        min-width: 620px;
        min-height: 620px;
        @include mq(sm) {
          display: none;
        }
      }
      .sp-bg2 {
        display: none;
        @include mq(sm) {
          display: block;
          width: 282px;
          height: 282px;
        }
      }
    }
    .top-popular-lessons {
      padding-left: 50px;
      padding-top: 66px;
      width: 100%;
      height: 786px;
      @include mq(sm) {
        padding-left: 20px;
        padding-top: 60px;
        height: 696px;
      }
      @media screen and (max-width: 650px) {
        .common-titles-title {
          white-space: normal !important;
          width: 170px !important;
        }
      }
      @include mq(sm) {
        .common-titles-title {
          white-space: normal !important;
          width: unset !important;
        }
      }
    }
    .top-new-lessons {
      padding-left: 50px;
      padding-top: 80px;
      width: 100%;
      height: 786px;
      @include mq(sm) {
        padding-left: 20px;
        padding-top: 60px;
        height: 696px;
      }
    }
  }
  .top-bottom {
    max-width: 1366px;
    width: 100%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 50px;
      top: 80px;
      width: 633px;
      height: 570px;
      background: rgba(253, 255, 255, 0.9);
      box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
      z-index: -1;
    }
    @include mq(sm) {
      height: 100%;
      margin-bottom: 80px;
      &::before {
        content: "";
        position: absolute;
        left: 20px;
        top: 60px;
        width: 168px;
        height: calc(100% - 120px);
        background: rgba(253, 255, 255, 0.9);
        opacity: 0.3;
        box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.25);
        z-index: -1;
      }
    }
    .top-bottom-top-bg {
      position: absolute;
      width: 584px;
      height: 584px;
      background: $primary;
      opacity: 0.8;
      border-radius: 50%;
      right: -289px;
      top: -277px;
      z-index: -1;
      @include mq(sm) {
        width: 255px;
        height: 255px;
        right: -127px;
        top: -127px;
      }
    }
    .top-bottom-bottom-bg {
      position: absolute;
      width: 584px;
      height: 584px;
      background: $secondary;
      opacity: 0.4;
      border-radius: 50%;
      left: -242px;
      bottom: -242px;
      z-index: -1;
      @include mq(sm) {
        width: 255px;
        height: 255px;
        left: -108px;
        bottom: -177px;
      }
    }
    .top-bottom-recommended-topics {
      max-width: 1266px;
      width: calc(100% - 100px);
      background: rgba(253, 255, 255, 0.9);
      backdrop-filter: blur(100px);
      margin-left: 50px;
      margin-top: 80px;
      margin-bottom: 50px;
      padding: 65px;
      padding-right: 50px;
      padding-bottom: 85px;
      overflow: hidden;
      @include mq(sm) {
        width: calc(100% - 40px);
        margin-left: 20px;
        margin-top: 60px;
        padding: 0;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 60px;
        min-height: 708px;
        height: 100%;
        position: relative;
        margin-bottom: 0;
        backdrop-filter: unset;
      }
      .top-bottom-recommended-topics-title {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: $black;
        text-align: left;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 40px;
          height: 0px;
          left: 0;
          bottom: -22px;
          border-bottom: 4px solid $black;
        }
        @include mq(sm) {
          margin-left: 00px;
          margin-top: 00px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 0.08em;
          color: $black;
          height: 80px;
          &::before {
            left: 0;
            bottom: -12px;
          }
        }
      }
      .top-bottom-recommended-topics-subtitle {
        margin-top: 40px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $black;
        opacity: 0.5;
        @include mq(sm) {
          margin-left: 0px;
          margin-top: 32px;
          font-weight: 500;
          font-size: 18px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $black;
        }
      }
      .top-bottom-content {
        display: flex;
        flex-wrap: wrap;
        max-width: 1150px;
        width: 100%;
        margin-top: 50px;
        @include mq(sm) {
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: center;
          min-height: 475px;
          height: 100%;
          margin-top: 15px;
          margin-bottom: 30px;
        }
        .top-bottom-content-item {
          width: 215px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 0.5px solid #02daa680;
          font-weight: 700;
          font-size: 14px;
          line-height: 1rem;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.08em;
          color: $black;
          margin-right: 15px;
          margin-top: 15px;
          cursor: pointer;
          background-color: rgba(54, 131, 245, 0.02);
          @media (any-hover: hover) {
            &:hover {
              background: $primary;
              border: 0.5px solid rgba(54, 131, 245, 0.5);
              color: $white;
              box-shadow: 0px 20px 30px rgba(54, 130, 243, 0.25);
            }
            &:active {
              background: $primary;
              border: 0.5px solid rgba(54, 131, 245, 0.5);
              color: $white;
              box-shadow: unset;
            }
          }
          @include mq(sm) {
            width: 100%;
            min-height: 80px;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.08em;
            color: $black;
            margin-right: 0px;
            background-color: rgba(54, 131, 245, 0.02);
          }
        }
        :nth-child(6),
        :nth-child(7),
        :nth-child(8),
        :nth-child(9),
        :nth-child(10) {
          border-bottom: 0.5px solid $primary;
        }
        @include mq(sm) {
          :nth-child(odd) {
            border-bottom: 0.5px solid $secondary;
          }
          :nth-child(even) {
            border-bottom: 0.5px solid $primary;
          }
        }
      }
    }
    .top-bottom-recommended-topics-more {
      display: none;
      @include mq(sm) {
        margin-left: 20px;
        display: flex;
        width: calc(100% - 40px);
        height: 60px;
        background: $black;
        box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .top-bottom-recommended-topics-more-text {
          margin-top: 21;
          margin-left: 50px;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $white;
        }
        svg {
          margin-right: 30px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
</style>
