import { inject } from "vue";
import { videoStore } from "./video";
import { searchStore } from "./search";

export default function globalStore() {
  return {
    videoStore: videoStore(),
    searchStore: searchStore(),
  };
}

export const GlobalStoreKey = Symbol("GlobalStore");
export function useGlobalStore() {
  const store = inject(GlobalStoreKey);
  if (!store) {
    throw new Error(`${GlobalStoreKey} is not provided`);
  }
  return store;
}
