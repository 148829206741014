import { createStore } from "vuex";
import dayjs from "dayjs";
import user from "./modules/user";

const a8IdKey = 'A8-ID'

const setA8IdInStorage = (a8Id) => {
  const now = dayjs();
  var values = JSON.stringify({
    id: a8Id,
    expired_at: now,
  });
  localStorage.setItem(a8IdKey, values)
}

const getA8IdInStorage = () => {
  const expiry = 1000 * 60 * 60 * 24 * 90; // 90日間
  const values = localStorage.getItem(a8IdKey);
  if (values) {
    const dataObj = JSON.parse(values)
    const isExpired = dayjs().diff(dataObj.expired_at) > expiry
    if (isExpired) {
      console.log('A8 ID: timeout')
      localStorage.removeItem(a8IdKey)
      return "";
    }
    return dataObj.id;
  }
  return  "";
}

export default createStore({
  state: {
    isSp: false,
    editorUsing: false,
    notice: 0,
    payment: 0,
    courseDetail: false,
    courseDetailURL: "",
    lectureDetailURL: "",
    isMaintenance: false,
    a8Id: getA8IdInStorage(),
  },
  mutations: {
    calcIsSp(state, value) {
      state.isSp = value;
    },
    calcIsEditorUsing(state, value) {
      state.editorUsing = value;
    },
    calcA8Id(state, value) {
      state.a8Id = value;
    },
    changeNoticeTab(state, value) {
      state.notice = value;
    },
    changePaymentTab(state, value) {
      state.payment = value;
    },
    changeCourseDetailFlag(state, value) {
      state.courseDetail = value;
    },
    changeCourseDetailURL(state, value) {
      state.courseDetailURL = value;
    },
    changeLectureDetailURL(state, value) {
      state.lectureDetailURL = value;
    },
    changeIsMaintenance(state, value) {
      state.isMaintenance = value;
    },
  },
  actions: {
    calcIsSp(context) {
      context.commit("calcIsSp", window.screen.width < 992);
    },
    calcIsEditorUsing(context, value) {
      context.commit("calcIsEditorUsing", value);
    },
    calcA8Id(context, value) {
      context.commit("calcA8Id", value);
      setA8IdInStorage(value);
    },
    changeNoticeTab(context, value) {
      context.commit("changeNoticeTab", value);
    },
    changePaymentTab(context, value) {
      context.commit("changePaymentTab", value);
    },
    changeCourseDetailFlag(context, value) {
      context.commit("changeCourseDetailFlag", value);
    },
    changeCourseDetailURL(context, value) {
      context.commit("changeCourseDetailURL", value);
    },
    changeLectureDetailURL(context, value) {
      context.commit("changeLectureDetailURL", value);
    },
    changeIsMaintenance(context, value) {
      context.commit("changeIsMaintenance", value);
    },
  },
  modules: {
    user,
  },
});
