import { reactive } from "vue";

export function searchStore() {
  const state = reactive({
    text: "",
  });

  return {
    get text() {
      return state.text;
    },

    setText(string) {
      state.text = string;
    },
  };
}
