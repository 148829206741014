<template>
  <div class="watch-swiper-item">
    <img :src="video?.image_url" alt="" class="watch-swiper-item-img" @click="seeVideo"/>
    <div v-if="isLogin" class="watch-swiper-item-mask">
      <div class="watch-swiper-item-mask-memo">
        <div class="watch-swiper-item-mask-memo-title">メモ:&nbsp;</div>
        <div class="watch-swiper-item-mask-memo-num">
          {{ video.memos_count }}件
        </div>
      </div>

      <icon
        v-if="viewState.isFavorite"
        @click="deleteFavorite()"
        name="bookmark-narrow-active"
        class="
          watch-swiper-item-mask-bookmark watch-swiper-item-mask-bookmark-active
        "
      ></icon>
      <icon
        v-else
        @click="addFavorite()"
        name="bookmark-narrow"
        class="watch-swiper-item-mask-bookmark"
      ></icon>
    </div>
    <div
      class="watch-swiper-item-progress"
      :style="{ width: `${video.progress_rate}%` }"
    ></div>
    <div class="watch-swiper-item-title">
      <div>{{ video.name }}</div>
    </div>

    <div class="watch-swiper-item-note">
      <template v-if="latestMemo">
        <div class="note-time">
          {{ displayMarkedTime(latestMemo.marked_time) }}
        </div>
        <div class="note-detail">
          {{ trimHtmlTag(latestMemo.memo) }}
        </div>
      </template>
      <template v-else>
        <div class="no-notes">No Notes</div>
      </template>
    </div>
    <div
      class="watch-swiper-item-btn watch-swiper-item-btn-animation"
      @click="seeVideo"
    >
      <div class="watch-swiper-item-btn-text">{{ buttonText }}</div>
      <icon name="chapter-detail-btn" class="watch-swiper-item-btn-icon"></icon>
    </div>
  </div>
</template>

<script>
import http from "@/utils/http";
import display from "@/utils/display";
import { defineComponent, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "WatchSwiperItem",
  props: ["imgIndex", "video", "buttonText"],
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const viewState = reactive({
      isFavorite: props["video"]?.is_favorite,
    });

    const isLogin = computed(() => store.state.user.isLogin);
    const latestMemo = computed(() => {
      return props["video"]?.material_memos?.length > 0
        ? props["video"].material_memos[0]
        : null;
    });

    // method
    const displayMarkedTime = (markedTime) => display.toMemoTime(markedTime);
    const trimHtmlTag = (string) =>
      string.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "");
    const seeVideo = () => {
      if (props["video"].next_attend_material == null) {
        router.push(`/lecture-detail/${props["video"].code}`);
      } else {
        router.push(
          `/view/${props["video"].code}/${props["video"].next_attend_material.chapter_code}/${props["video"].next_attend_material.code}`
        );
      }
    };
    const addFavorite = () => {
      // 先にお気に入り状態にしておく
      viewState.isFavorite = true;

      http
        .post(`/api/lectures/${props["video"].key}/favorites`)
        .then((response) => {
          viewState.isFavorite = true;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          viewState.isFavorite = false;
          return error.response;
        });
    };
    const deleteFavorite = () => {
      // 先にお気に入り解除状態にしておく
      viewState.isFavorite = false;

      http
        .delete(`/api/lectures/${props["video"].key}/favorites`)
        .then((response) => {
          viewState.isFavorite = false;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          viewState.isFavorite = true;
          return error.response;
        });
    };

    return {
      viewState,
      isLogin,
      latestMemo,

      // method
      displayMarkedTime,
      trimHtmlTag,
      seeVideo,
      addFavorite,
      deleteFavorite,
    };
  },
});
</script>

<style lang="scss" scoped>
.watch-swiper-item {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:hover {
    .watch-swiper-item-img {
      border: 0.5px solid rgba(0, 0, 0, 0.5);
    }
  }
  @include mq(sm) {
    margin-top: 0px;
  }
  .watch-swiper-item-img {
    cursor: pointer;
    width: 300px;
    height: 300px;
    border: 0.5px solid #fdffff;
    @include mq(sm) {
      border: 0.5px solid rgba(0, 0, 0, 0.5);
      width: 275px;
      height: 180px;
      object-fit: cover;
    }
  }
  .watch-swiper-item-mask {
    position: absolute;
    width: 300px;
    height: 60px;
    background: rgba(15, 15, 17, 0.1);
    top: 230px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-left: 0.5px solid #fdffff;
    border-right: 0.5px solid #fdffff;
    @include mq(sm) {
      top: 120px;
      width: 275px;
      height: 50px;
      border-left: 0.5px solid rgba(0, 0, 0, 0);
      border-right: 0.5px solid rgba(0, 0, 0, 0);
    }
    .watch-swiper-item-mask-memo {
      display: flex;
      align-items: center;
      margin-right: 175px;
      @include mq(sm) {
        margin-right: 147px;
      }
      .watch-swiper-item-mask-memo-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.09em;
        color: $white;
        opacity: 0.8;
        @include mq(sm) {
          font-weight: 500;
          font-size: 12px;
          line-height: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.08em;
          color: $white;
          opacity: 0.8;
        }
      }
      .watch-swiper-item-mask-memo-num {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 70px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.08em;
        color: $white;
        @include mq(sm) {
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.08em;
          color: $white;
        }
      }
    }
    .watch-swiper-item-mask-bookmark {
      cursor: pointer;
      margin-right: 20px;
      width: 19px;
      height: 27px;
      opacity: 1;
      @include mq(sm) {
        margin-right: 28px;
      }
    }
    .watch-swiper-item-mask-bookmark-active {
      cursor: pointer;
      box-shadow: inset 0px 20px 12px rgba(54, 131, 245, 0.25),
        0px 20px 30px rgba(54, 131, 245, 0.25);
    }
    &::after {
      content: "";
      position: absolute;
      right: 53px;
      top: 20px;
      width: 0px;
      height: 20px;
      border-right: 0.5px solid $white;
      @include mq(sm) {
        right: 67px;
        top: 15px;
      }
    }
  }
  .watch-swiper-item-progress {
    position: absolute;
    max-width: 298px;
    height: 9px;
    background: rgba(2, 218, 166, 1);
    top: 290px;
    left: 1px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mq(sm) {
      top: 170px;
      width: 87.5px;
      max-width: 273px;
    }
  }
  .watch-swiper-item-title {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(sm) {
      width: 275px;
    }
    div {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 260px;
      height: 60px;
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $black;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include mq(sm) {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 20px;
        margin-right: 20px;
        width: 275px;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $black;
      }
    }
  }
  .watch-swiper-item-note {
    opacity: 0;
    height: 0px;
    display: flex;
    flex-direction: row;
    width: 300px;
    // justify-content: space-between;
    // align-items: center;
    @include mq(sm) {
      opacity: 1;
      height: 134px;
      width: 275px;
      flex-direction: column;
    }
    .note-time {
      width: 42px;
      height: 28px;
      margin-left: 20px;
      margin-top: 15px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 14px;
      // line-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.08em;
      color: $black;
      @include mq(sm) {
        margin-top: 30px;
        width: 35px;
        height: 14px;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.08em;
        color: $black;
      }
    }
    .note-detail {
      width: 208px;
      height: 40px;
      margin-left: 10px;
      margin-top: 20px;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
      letter-spacing: 0.09em;
      color: rgba(89, 88, 87, 0.8);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include mq(sm) {
        margin-left: 20px;
        margin-top: 20px;
        width: 235px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        align-items: center;
        letter-spacing: 0.09em;
        color: rgba(89, 88, 87, 0.8);
      }
    }
    .no-notes {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      letter-spacing: 0.09em;
      color: $grey;
      height: 100%;
    }
  }
  .watch-swiper-item-btn {
    cursor: pointer;
    opacity: 0;
    width: 300px;
    height: 0px;
    background: $black;
    display: flex;
    justify-content: space-between;
    min-width: 227px;
    align-items: center;
    background: $black;
    position: relative;
    z-index: 2;
    @include mq(sm) {
      opacity: 1;
      width: 275px;
      height: 60px;
    }
    .watch-swiper-item-btn-text {
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 0.08em;
      color: $white;
      margin-left: 30px;
      z-index: 2;
      @include mq(sm) {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $white;
        margin-left: 30px;
        // margin-right: 89px;
        white-space: nowrap;
      }
    }
    .watch-swiper-item-btn-icon {
      width: 40px;
      height: 10px;
      margin-right: 20px;
      z-index: 2;
    }
    @keyframes watch-swiper-item-btn-hover-keyframe {
      from {
        width: 0px;
      }
      to {
        width: 100%;
      }
    }
    &:hover {
      &::before {
        content: "";
        left: 0;
        height: 60px;
        position: absolute;
        background: $primary;
        z-index: 0;
        animation: watch-swiper-item-btn-hover-keyframe 0.3s linear 0s 1
          forwards;
        @include mq(sm) {
          animation: unset;
        }
      }
      &::after {
        content: "";
        left: 0;
        height: 60px;
        position: absolute;
        background: $black;
        z-index: 1;
        animation: watch-swiper-item-btn-hover-keyframe 0.2s linear 0.6s 1
          forwards;
        @include mq(sm) {
          animation: unset;
        }
      }
    }
  }
  &:hover {
    // @keyframes watch-swiper-item-title-keyframe {
    //   from {
    //     opacity: 0;
    //     height: 0px;
    //   }
    //   to {
    //     opacity: 1;
    //     height: 100%;
    //   }
    // }
    // .watch-swiper-item-title {
    //   opacity: 0;
    //   animation: watch-swiper-item-title-keyframe 0.4s linear 0.2s 1 forwards;
    //   @include mq(sm) {
    //     opacity: 1;
    //     animation: unset;
    //   }
    // }
    .watch-swiper-item-mask {
      border-left: 0.5px solid rgba(0, 0, 0, 0);
      border-right: 0.5px solid rgba(0, 0, 0, 0);
    }
    @keyframes watch-swiper-item-keyframe {
      from {
        opacity: 0;
        height: 80px;
      }
      to {
        opacity: 1;
        height: 80px;
      }
    }
    .watch-swiper-item-note {
      animation: watch-swiper-item-keyframe 0.2s linear 0.2s 1 forwards;
      @include mq(sm) {
        animation: unset;
      }
    }
    @keyframes watch-swiper-item-btn-keyframe {
      from {
        opacity: 0;
        height: 0px;
      }
      to {
        opacity: 1;
        height: 60px;
      }
    }
    .watch-swiper-item-btn-animation {
      animation: watch-swiper-item-btn-keyframe 0.2s linear 0.2s 1 forwards;
      @include mq(sm) {
        animation: unset;
      }
    }
  }
}
</style>
