import dayjs from "dayjs";

// 画面表示用
const display = {
  toTime: (string) => {
    // H:i:s形式を時分表示に変換する
    const timeList = string.split(":");
    return `${parseInt(timeList[0], 10)}h${parseInt(timeList[1], 10)}m`;
  },
  toVideoTime: (number) => {
    // 秒数を00:00に変換する
    const intSeconds = parseInt(number, 10);
    const minutesString = `${parseInt(intSeconds / 60, 10) % 60}`.padStart(
      2,
      "0"
    );
    const secondsString = `${intSeconds % 60}`.padStart(2, "0");
    return `${minutesString}:${secondsString}`;
  },
  toHis: (number) => {
    // 秒数を00:00:00に変換する
    const intSeconds = parseInt(number, 10);
    const hoursString = `${parseInt(
      parseInt(intSeconds / 60, 10) / 60,
      10
    )}`.padStart(2, "0");
    const minutesString = `${parseInt(intSeconds / 60, 10) % 60}`.padStart(
      2,
      "0"
    );
    const secondsString = `${intSeconds % 60}`.padStart(2, "0");
    return `${hoursString}:${minutesString}:${secondsString}`;
  },
  toYm: (string) => {
    const tempDay = dayjs(string);
    return tempDay.format("YYYYMM");
  },
  toMemoTime: (string) => {
    // H:i:s形式を 1:04:06 / 5:03 / 0:50 表示に変換する
    const timeList = string.split(":");
    timeList[0] = Number(timeList[0]);
    if (timeList[0]) {
      return timeList[0] + ":" + timeList[1] + ":" + timeList[2];
    }
    timeList[1] = Number(timeList[1]);
    return timeList[1] + ":" + timeList[2];
  },
  timestampToHm: (timestamp) => {
    const tempDay = dayjs(`2021-01-01 ${timestamp}`);
    return `${tempDay.hour()}h${tempDay
      .minute()
      .toString()
      .padStart(2, "0")}m`;
  },
  toDate: (string) => {
    const tempDay = dayjs(string);
    return tempDay.format("YYYY/MM/DD");
  },
  toLikeCountString: (likesCount) => {
    if (likesCount < 50) {
      // 50未満は何も表示しない
      return "";
    } else if (likesCount < 1000) {
      // 1000未満はそのまま表示
      return likesCount.toString();
    } else {
      // 1000以上は「K」表示
      return `${Math.floor(likesCount / 1000)}K`;
    }
  },
  toNoticeTime: (timestamp) => {
    return dayjs(timestamp).format("YYYY/MM/DD HH:mm");
  },
  timestampToYmd: (timestamp) => {
    return dayjs(timestamp).format("YYYY/MM/DD");
  },
  toLink: (text) => {
    return text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1', target='_blank'>$1</a>");
  }

};
export default display;
