<template>
<div class="isMaintenance">
  <div class="commonHeader">
    <div  class="pc-header">
      <div class="pc-menu">
        <icon name="logo" class="pc-logo"></icon>
      </div>
    </div>
    <div class="sp-header">
      <div class="logo">
        <icon name="logo"></icon>
      </div>
    </div>
  </div>
  <main>
    <content-page-header>
      <common-page-title title="現在システムメンテナンス中です" />
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <section>
          <div class="maintenance-message">
            <p class="maintenance-message-text">いつもジッセン!DXをご利用いただきまして、誠にありがとうございます。<br>ただいまメンテナンスのため一時サービスを停止しております。</p>
            <p class="maintenance-message-text">大変ご不便をおかけいたしますが、サービス再開まで今しばらくお待ちください。</p>
          </div>
        </section>
      </panel-body>
    </div>
  </main>
  <div class="footer">
    <div class="footer-container">
      <div class="verticalLineLeft"></div>
      <div class="footer-nav">
        <div class="footerText1">
          <img class="image" src="@/assets/img/footerIMG1.png"/>
          <span class="fontStyle1">デジタル時代に求められる最も実践的なDX専門オンラインスクール</span>
        </div>
        <div class="footerText2">
        </div>
        <div class="footerText3">
        </div>
        <div class="footerText4">
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="copyright-container">
      <template v-if="nowYear > 2021">
        Copyright (C) IREP Co., Ltd. All Rights Reserved.
      </template>
      <template v-else>
        Copyright (C) IREP Co., Ltd. All Rights Reserved.
      </template>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, computed } from "vue";
import dayjs from "dayjs";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
export default defineComponent({
  name: "maintenance",

  components: {
    contentPageHeader,
    CommonPageTitle,
    PanelBody,
  },
  props: [],
  setup() {
    const nowYear = computed(() => dayjs().format("YYYY"));
    return {
      nowYear
    };
  },
});
</script>

<style lang="scss" scoped>
.isMaintenance {
  position: relative;
  z-index: 9999;
}
.pc-header {
  max-width: 1366px;
  min-height: 80px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(50px);
  z-index: 3;
  padding: 0 50px;
  margin: 0 auto;
  @include mq(sm) {
    display: none;
  }
}
.sp-header {
  display: none;
  @include mq(sm) {
    display: flex;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    background: #fff;
    .logo {
      flex: 1;
      height: 60px;
      border-bottom: 0.5px solid $black-50;
      svg {
        width: 90px;
        height: 25px;
        margin-left: 20px;
        margin-top: 17px;
      }
    }
  }
}
.pc-logo {
  width: 120px;
  height: 80px;
  margin-right: 4%;
}
::v-deep(.content-header) {
  height: auto;
  padding: 100px 100px 200px;
  background-size: cover;
  @include mq(sm) {
    padding: 140px 20px 200px;
  }
}
.content-body {
  position: relative;
  margin-top: -137px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.maintenance-message {
  position: relative;
  padding-bottom: 200px;
  @include mq(sm) {
    padding-bottom: 100px;
  }
  &::before {
    content: "";
    width: 10px;
    height: 62px;
    position: absolute;
    top: 0;
    left: -65px;
    background-color: #3683f5;
    @include mq(sm) {
      height: 40px;
      left: -30px;
    }
  }
}
.maintenance-message-text {
  line-height: 1.7;
  &:nth-of-type(n + 2) {
    margin-top: 2em;
  }
}
.footer {
  position: relative;
  background: $black;
  z-index: 1;
  &-container {
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    .verticalLineLeft {
      float: left;
      max-width: 115px;
      width: 115px;
      height: auto;
      @include mq(sm) {
        min-width: 50px;
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
      }
    }
    .footer-nav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 1136px;
      @include mq(sm) {
        margin-left: 20px;
        flex-direction: column;
      }
      .footer-link {
        color: #ffffff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
      .footerText1 {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 180px;
        min-height: 390px;
        @include mq(sm) {
          min-width: 200px;
          width: 200px;
          min-height: 325px;
          border-right: none;
        }
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
        .image {
          width: 89.29px;
          height: 25px;
          margin-top: 160px;
          @include mq(sm) {
            margin-top: 120px;
          }
        }
        .fontStyle1 {
          text-align: left;
          max-width: 254px;
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.1em;
          color: $white;
          margin-top: 30px;
          @include mq(sm) {
            display: block;
            width: 275px;
          }
        }
      }
      .footerText2 {
        display: flex;
        flex-direction: column;
        text-align: left;
        flex: 1;
        min-width: 100px;
        height: 390px;
        @include mq(sm) {
          display: none;
        }
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $white;
      }
      .footerText3 {
        display: flex;
        flex-direction: column;
        text-align: left;
        flex: 1;
        min-width: 160px;
        height: 390px;
        @include mq(sm) {
          display: none;
          width: 200px;
          max-height: 104px;
          border-right: none;
        }
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $white;
      }
      .footerText4 {
        display: flex;
        flex-direction: column;
        text-align: left;
        flex: 1;
        min-width: 140px;
        height: 390px;
        @include mq(sm) {
          display: none;
        }
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $white;
      }
    }
  }
}
.copyright {
  background-color: #262628;
  &-container {
    max-width: 1366px;
    margin: 0 auto;
    padding-left: 115px;
    background-color: #262628;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 60px;
    letter-spacing: 0.08em;
    color: $white;
    @include mq(sm) {
      padding-left: 50px;
    }
  }
}
</style>
