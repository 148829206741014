<template>
  <div class="watch-swiper">
    <commonTitle :title="title" :subTitle="subTitle"></commonTitle>

    <div
      class="watch-swiper-button-prev"
      :class="'watch-swiper-button-prev' + cid"
    >
      <icon
        name="swiper-button-prev-btn"
        class="swiper-button-prev-btn-icon"
      ></icon>
    </div>
    <div
      class="watch-swiper-button-next"
      :class="'watch-swiper-button-next' + cid"
    >
      <icon
        name="swiper-button-next-btn"
        class="swiper-button-next-btn-icon"
      ></icon>
    </div>
    <div class="swiper-index">
      <div class="swiper-index-page-text">Page&nbsp;</div>
      {{ viewState.realIndex }}/{{ viewState.videosCount }}
    </div>
    <swiper
      class="swiper-container"
      :loop="viewState.videosCount > 1"
      :slidesPerView="4"
      :slidesPerGroup="4"
      :breakpoints="breakpoints"
      :navigation="{
        prevEl: '.watch-swiper-button-prev' + cid,
        nextEl: '.watch-swiper-button-next' + cid,
      }"
      @activeIndexChange="activeIndexChange"
    >
      <swiper-slide
        v-for="(x, index) in viewState.videosCount"
        :key="index"
        :class="'watch-swiper-items'"
      >
        <watchSwiperItem
          :imgIndex="index % 4"
          :class="(index + 1) % 2 == 0 ? 'watch-swiper-item-mt90' : ''"
          :buttonText="buttonText"
          :video="videos[index]"
        ></watchSwiperItem>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import watchSwiperItem from "./WatchSwiperItem.vue";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import { defineComponent, reactive } from "vue";
SwiperCore.use([Navigation]);

export default defineComponent({
  props: ["cid", "title", "subTitle", "videos", "buttonText"],
  components: {
    watchSwiperItem,
    Swiper,
    SwiperSlide,
    commonTitle,
  },
  setup(props) {
    const viewState = reactive({
      videosCount: props.videos.length ? props.videos.length : 1,
      realIndex: 1,
    });

    const breakpoints = {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 28,
      },
      766: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 28,
      },
      1036: {
        slidesPerView: 4,
        slidesPerGroup: 2,
        spaceBetween: 28,
      },
      1366: {
        slidesPerView: 4,
        slidesPerGroup: 2,
        spaceBetween: 28,
      },
    };

    // method
    const activeIndexChange = ({ realIndex }) => {
      viewState.realIndex = realIndex + 1;
    };

    return {
      viewState,
      breakpoints,

      // method
      activeIndexChange,
    };
  },
});
</script>
<style lang="scss" scoped>
.watch-swiper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1349px;
  height: 721px;
  @include mq(sm) {
    width: 335px;
  }
  .watch-swiper-button-prev {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 175px;
    top: 21px;
    .swiper-button-prev-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      right: unset;
      top: 138px;
      left: 30px;
      .swiper-button-prev-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .watch-swiper-button-next {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 115px;
    top: 21px;
    .swiper-button-next-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      right: unset;
      top: 138px;
      left: 70px;
      .swiper-button-next-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .swiper-index {
    display: none;
    @include mq(sm) {
      display: flex;
      width: 88px;
      height: 15px;
      align-items: baseline;
      position: relative;
      top: -24px;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black-50;
      justify-content: flex-end;
    }
  }
  .swiper-container {
    margin-left: 65px;
    max-width: 1284px;
    width: 1284px;
    .watch-swiper-items {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .watch-swiper-item-mt90 {
        margin-top: 90px;
      }
    }
    @include mq(sm) {
      margin-top: 15px;
      margin-left: 30px;
      margin-right: 30px;
      width: 275px;
      .watch-swiper-items {
        height: 494px;
        .watch-swiper-item-mt90 {
          margin-top: 0px;
        }
      }
    }
  }
  .common-title {
    &::before {
      top: 0px;
      @include mq(sm) {
        top: 30px;
      }
    }
    ::v-deep(.common-title-area) {
      @include mq(sm) {
        height: unset;
      }
      .common-titles-title {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: $black;
        @media screen and (max-width: 750px) {
          width: 300px;
          text-align: left;
        }
        @include mq(sm) {
          margin-top: 10px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 0.08em;
          color: $black;
          text-align: left;
        }
      }
      .common-titles-sub-title {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.08em;
        color: $black-50;
      }
    }
  }
}
</style>
