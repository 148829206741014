<template>
  <div
    class="footer"
    :class="isEnterprise ? 'footer-enterprise' : ''"
  >
    <div class="footer-container">
      <div class="verticalLineLeft"></div>
      <div class="footer-nav">
        <div class="footerText1">
          <img class="image" src="@/assets/img/footerIMG1.png"/>
          <span class="fontStyle1">デジタル時代に求められる最も実践的なDX専門オンラインスクール</span>
        </div>

        <!-- エンタープライズ -->
        <template
          v-if="isEnterprise"
        >
          <div class="footerText2">
            <span class="fontStyle1"><a :href="host + '/ジッセン!DX_利用規約.pdf'" target="_blank" class="footer-link">利用規約</a></span>
          </div>

          <div class="footerText3">
            <span class="fontStyle1"><a href="https://www.irep.co.jp/policy/" target="_blank" class="footer-link">プライバシーポリシー</a></span>
          </div>

          <div class="footerText4">
            <span class="fontStyle1">
              <router-link to="/inquiry/input" class="footer-link">
                お問い合わせ
              </router-link>
            </span>
          </div>
        </template>

        <!-- プレミアム・ビジネス・未ログイン -->
        <template
          v-else
        >
          <div class="footerText2">
            <span class="fontStyle1"><a href="https://www.irep.co.jp/" target="_blank" class="footer-link">運営会社</a></span>
            <span class="fontStyle2"><a :href="host + '/ジッセン!DX_利用規約.pdf'" target="_blank" class="footer-link">利用規約</a></span>
            <span class="fontStyle3"><a :href="host + '/ジッセン!DX_特定商取引法に基づく表記.pdf'" target="_blank" class="footer-link">特定商取引法に基づく表記</a></span>
          </div>
          <div class="footerText3">
            <span class="fontStyle1"><a href="https://www.irep.co.jp/policy/" target="_blank" class="footer-link">プライバシーポリシー</a></span>
            <span class="fontStyle2">
              <router-link to="/inquiry/business/input" class="footer-link">
                法人向けサービス
              </router-link>
            </span>
          </div>
          <div class="footerText4">
            <span class="fontStyle1">
              <router-link to="/inquiry/input" class="footer-link">
                お問い合わせ
              </router-link>
            </span>
            <span class="fontStyle2"><a :href="host + '/ジッセン!DX_ユーザーマニュアル.pdf'" target="_blank" class="footer-link">ユーザーマニュアル</a></span>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="copyright-container">
      <template v-if="nowYear > 2021">
        Copyright (C) IREP Co., Ltd. All Rights Reserved.
      </template>
      <template v-else>
        Copyright (C) IREP Co., Ltd. All Rights Reserved.
      </template>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, computed } from "vue";
import dayjs from "dayjs";

export default defineComponent({
  name: 'commonfooter',
  setup() {
    const store = useStore();
    const isEnterprise = computed(() => store.state.user.isEnterprise)

    const nowYear = computed(() => dayjs().format("YYYY"));
    const host = computed(() => window.location.protocol + '//' + window.location.host);

    return {
      isEnterprise,
      nowYear,
      host
    };
  },
});
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  background: $black;
  z-index: 1;
  &-container {
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    @include mq(sm) {
      height: 737px;
    }
    .verticalLineLeft {
      float: left;
      max-width: 115px;
      width: 115px;
      height: auto;
      @include mq(sm) {
        min-width: 50px;
        height: 737px;
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
      }
    }
    .footer-nav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 1136px;
      @include mq(sm) {
        margin-left: 20px;
        flex-direction: column;
      }
      .footer-link {
        color: #ffffff;
        text-decoration:none;

        &:hover {
          text-decoration: underline;
        } 
      }
      .footerText1 {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 180px;
        min-height: 390px;
        @include mq(sm) {
          min-width: 200px;
          width: 200px;
          min-height: 325px;
          border-right: none;
        }
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
        .image {
          width: 89.29px;
          height: 25px;
          margin-top: 160px;
          @include mq(sm) {
            margin-top: 120px;
          }
        }
        .fontStyle1 {
          text-align: left;
          max-width: 254px;
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.1em;
          color: $white;
          margin-top: 30px;
          @include mq(sm) {
            display: block;
            width: 275px;
          }
        }
      }
      .footerText2 {
        display: flex;
        flex-direction: column;
        text-align: left;
        flex: 1;
        min-width: 100px;
        height: 390px;
        @include mq(sm) {
          width: 200px;
          max-height: 154px;
          border-right: none;
        }
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $white;
        .fontStyle1 {
          margin-top: 216px;
          margin-left: 30px;
          @include mq(sm) {
            margin-top: 0px;
            margin-left: 0px;
          }
        }
        .fontStyle2 {
          margin-top: 11px;
          margin-left: 30px;
          @include mq(sm) {
            margin-top: 20px;
            margin-left: 0px;
          }
        }
        .fontStyle3 {
          margin-top: 11px;
          margin-left: 30px;
          @include mq(sm) {
            margin-top: 20px;
            margin-left: 0px;
          }
        }
      }
      .footerText3 {
        display: flex;
        flex-direction: column;
        text-align: left;
        flex: 1;
        min-width: 160px;
        height: 390px;
        @include mq(sm) {
          width: 200px;
          max-height: 104px;
          border-right: none;
        }
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $white;
        .fontStyle1 {
          margin-top: 216px;
          margin-left: 30px;
          @include mq(sm) {
            margin-top: 0px;
            margin-left: 0px;
          }
        }
        .fontStyle2 {
          margin-top: 11px;
          margin-left: 30px;
          @include mq(sm) {
            margin-top: 20px;
            margin-left: 0px;
          }
        }
      }
      .footerText4 {
        display: flex;
        flex-direction: column;
        text-align: left;
        flex: 1;
        min-width: 140px;
        height: 390px;
        @include mq(sm) {
          width: 200px;
          height: 164px;
          border-right: none;
        }
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $white;
        .fontStyle1 {
          margin-top: 216px;
          margin-left: 30px;
          @include mq(sm) {
            margin-top: 0px;
            margin-left: 0px;
          }
        }
        .fontStyle2 {
          margin-top: 11px;
          margin-left: 30px;
          @include mq(sm) {
            margin-top: 20px;
            margin-left: 0px;
          }
        }
      }
    }
  }
  &.footer-enterprise {
    .footer-container {
      @include mq(sm) {
        height: 549px;
      }
      .footer-nav {
        .footerText4 {
          @include mq(sm) {
            margin-bottom: 74px;
          }
        }
      }
    }
  }
}
.copyright {
  background-color: #262628;
  &-container {
    max-width: 1366px;
    margin: 0 auto;
    padding-left: 115px;
    background-color: #262628;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 60px;
    letter-spacing: 0.08em;
    color: $white;
    @include mq(sm) {
      padding-left: 50px;
    }
  }
}
</style>
