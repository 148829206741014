import http from '@/utils/http';

const TokenKey = 'Request-Token'

const setTokenInStorage = (value, auto) => {
  var curtime = new Date().getTime()
  var valueDate = JSON.stringify({
    val: value,
    timer: curtime,
  })
  if (auto) {
    localStorage.setItem(TokenKey, valueDate)
  } else {
    sessionStorage.setItem(TokenKey, valueDate)
  }
}

const getToken = () => {
  var exp = 60 * 60 * 24 * 1000
  var vals = localStorage.getItem(TokenKey) || sessionStorage.getItem(TokenKey)
  if (vals) {
    var dataObj = JSON.parse(vals)
    var isTimed = new Date().getTime() - dataObj.timer > exp
    if (isTimed) {
      console.log('Error: timeout')
      localStorage.removeItem(TokenKey)
      return null
    }
    return dataObj.val
  }
  return null
}
const clearLocalStorage = () => {
  localStorage.clear()
}
const clearSessionStorage = () => {
  sessionStorage.clear()
}
let errorsDef = {
  email: [],
  password: [],
  message: '',
};
const userStore = {
  state: {
    isLogin: getToken() ? true : false,
    token: getToken(),
    servicePlanId: 0, // サービスプランID（1：プレミアムプラン、2：ビジネスプラン、3：エンタープライズプラン）
    isEnterprise: 0,
    availableUsers: 0, // 利用可能ユーザー数
    paymentType: 0, // 決済種別（1：クレジットカード決済）
    hasUnread: false,
    name: "",
    headerToNoticeTab: false,
    login: {
      email: "",
      password: "",
      auto: false,
      errors: errorsDef,
    },
    signUp: {
      tabNation: 1,
      email: "",
      confirmEmail: ""
    }
  },
  mutations: {
    doLogin(state, value) {
      state.isLogin = value
    },
    doLogout(state, value) {
      state.isLogin = value
    },
    changeRole(state, value) {
      state.role = value
    },
    setToken(state, value) {
      state.token = value
    },
    setServicePlanId(state, value) {
      state.servicePlanId = value
    },
    setIsEnterprise(state, value) {
      state.isEnterprise = value
    },
    setAvailableUsers(state, value) {
      state.availableUsers = value
    },
    setPaymentType(state, value) {
      state.paymentType = value
    },
    setHasUnread(state, value) {
      state.hasUnread = value
    },
    setName(state, value) {
      state.name = value
    },
    setHeaderToMypage(state, value) {
      state.headerToNoticeTab = value
    },
    setLoginErrors(state, value) {
      state.login.errors = value
    },
    setLogin(state, { email, password, auto }) {
      state.login.email = email
      state.login.password = password
      state.login.auto = auto
    },
    clearToken(state) {
      state.token = null
    },
    clearLogin(state) {
      state.login.email = ""
      state.login.password = ""
      state.login.errors = errorsDef
    }
  },
  actions: {
    async login(context) {
      context.commit('setLoginErrors', {
        email: [],
        password: [],
        message: '',
      })
      return await http
        .post("/api/login", {
          email: context.getters.loginEmail,
          password: context.getters.loginPassword,
        })
        .then(response => {
          if (context.getters.loginAuto) {
            setTokenInStorage(response.data.token, true);
            clearSessionStorage();
          } else {
            setTokenInStorage(response.data.token, false);
            clearLocalStorage();
          }
          context.commit('setToken', response.data.token)
          context.commit('doLogin', true)
        })
        .catch(error => {
          if (error.config && error.response && error.response.status === 400) {
            context.commit('setLoginErrors', { ...errorsDef, ...error.response.data.errors })
          } else {
            context.commit('setLoginErrors', { ...errorsDef, ...{ message: error.response.data.message } })
          }
          console.dir()
        });
    },
    async customersMe(context) {
      return await http
        .get("/api/customers/me")
        .then(response => {
          context.commit('setServicePlanId', response.data.service_plan_id)
          context.commit('setIsEnterprise', response.data.service_plan_id === 3)
          context.commit('setAvailableUsers', response.data.available_users)
          context.commit('setPaymentType', response.data.payment_type)
        })
        .catch(error => {
          console.log(error)
        });
    },
    async noticesCheckUnread(context) {
      return await http
        .get("/api/notices/check-unread")
        .then(response => {
          context.commit('setHasUnread', response.data.has_unread)
        })
        .catch(error => {
          console.log(error)
        });
    },
    logout(context) {
      http.post("/api/logout").then(() => {
        console.log("logout")
      })
      .catch(error => {
        console.log(error)
      })
      .finally(error => {
        console.log(error)
        
        clearSessionStorage();
        clearLocalStorage();
        context.commit('clearToken')
        context.commit('clearLogin')
        context.commit('doLogout', false)
        location.href = '/';
      });
    },
    changeRole(context, value) {
      context.commit('changeRole', value)
    },
    setLogin(context, { email, password, auto }) {
      context.commit('setLogin', { email, password, auto })
    },
    setToken(context, value) {
      context.commit('setToken', value)
    },
    clearToken(context) {
      context.commit('clearToken')
    },
    clearLogin(context) {
      context.commit('clearLogin')
    },
  },
  getters: {
    loginEmail(state) {
      return state.login.email
    },
    loginPassword(state) {
      return state.login.password
    },
    loginAuto(state) {
      return state.login.auto
    },
    token(state) {
      return state.token
    },
  },
}

export default userStore
